import React from 'react';
import { AuthProvider, useAuth } from './utils/authContext';
import { ApolloProvider } from '@apollo/client';
import client from './apollo/client';
import PrivateRoute from './utils/PrivateRoute';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Overview from './pages/Overview';
import Catalog from './pages/Catalog';
import Personalization from './pages/Personalization';
import Statistics from './pages/Statistics';
import HelpSupport from './pages/HelpSupport';
import Profile from './pages/Profile';
import Login from './pages/Login';
import Register from './pages/Register';
import Verify from './pages/Verify';
import CatalogSetup from './components/CatalogSetup';
import ResetPassword from './components/ResetPassword'
import SetNewPassword from './components/SetNewPassword';
import { REFRESH_TOKEN } from './graphql/mutations';
import { useMutation } from '@apollo/client';
import { useEffect, useState, useCallback } from 'react';
import { TokenManager } from './utils/tokenManager';
import { subscribeUserToPush } from './utils/pushNotifications';
import { Navigate } from 'react-router-dom';

const AppInitialization = ({ children }) => {
  const { login, refetchUserData } = useAuth();
  const [refreshToken] = useMutation(REFRESH_TOKEN);
  const [isInitializing, setIsInitializing] = useState(true);

  const refreshTokens = useCallback(async () => {
    const success = await TokenManager.refreshTokens();
    if (success) {
      await refetchUserData(); // Refetch user data after token refresh
    }
    setIsInitializing(false);
  }, [refetchUserData]);

  useEffect(() => {
    refreshTokens();

    const refreshInterval = setInterval(() => {
      refreshTokens();
    }, 10 * 60 * 1000); // Refresh every 10 minutes

    return () => clearInterval(refreshInterval);
  }, [refreshTokens]);

  if (isInitializing) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};

const App = () => {

  // useEffect(() => {
  //   const initializePushNotifications = async () => {
  //     try {
  //       await subscribeUserToPush();
  //     } catch (error) {
  //       console.error('Failed to initialize push notifications:', error);
  //       // Optionally, you can show a user-friendly error message here
  //     }
  //   };

  //   initializePushNotifications();
  // }, []);


  return (
    <AuthProvider>
      <AppInitialization>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/verify/:token" element={<Verify />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset/:token" element={<SetNewPassword />} />
            <Route path="/assets/" element={<Navigate to="/" replace />} />


            <Route element={<PrivateRoute />}>
              <Route path="/catalog-setup" element={<CatalogSetup />} />
              <Route path="/" element={<Overview />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/catalog" element={<Catalog />} />
              <Route path="/personalization" element={<Personalization />} />
              <Route path="/statistics" element={<Statistics />} />
              <Route path="/help-support" element={<HelpSupport />} />
              <Route path="/profile" element={<Profile />} />
            </Route>

            {/* Redirect /assets/ to /assets */}
            {/* <Route path="/assets/" element={<Navigate to="/" replace />} /> */}

            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </AppInitialization>
    </AuthProvider>
  );
};

export default App;
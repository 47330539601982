import React, { useState } from 'react';
import { X, Eye, EyeOff } from 'lucide-react';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD_MUTATION } from '../graphql/mutations';

const ChangePasswordPopup = ({ isOpen, onClose }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD_MUTATION, {
        onCompleted: (data) => {
            if (data.changePassword.success) {
                setSuccess(data.changePassword.message);
                setError('');
                // Reset form fields
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } else {
                setError(data.changePassword.message);
                setSuccess('');
            }
        },
        onError: (error) => {
            setError(error.message);
            setSuccess('');
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (newPassword !== confirmPassword) {
            setError('New passwords do not match');
            return;
        }

        if (newPassword.length < 6 || (!/^(?=.*[A-Z])/.test(currentPassword)) || (!/^(?=.*[a-z])/.test(currentPassword))) {
            setError('Your new password must be at least 6 characters long and contain both uppercase and lowercase letters.');
            return;
        }


        // if (!/^(?=.*[A-Z])/.test(currentPassword)) {
        //     setError('Password must contain at least one uppercase letter');
        //     return;
        // }

        // if (!/^(?=.*[a-z])/.test(currentPassword)) {
        //     setError('Password must contain at least one lowercase letter');
        //     return;
        // }

        // if
        //     (!/^(?=.*\d)/.test(password)) {
        //     setError('Password must contain at least one number');
        //     return;
        // }

        changePassword({ variables: { currentPassword, newPassword } });
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold text-[#191970]">Change Password</h2>
                    <button onClick={onClose} className="text-gray-500 bg-white hover:text-gray-700">
                        <X size={24} />
                    </button>
                </div>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
                            Current Password
                        </label>
                        <div className="mt-1 relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="currentPassword"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                required
                                className="shadow-sm focus:ring-[#2424db] px-2 py-1 bg-white focus:border-[#2424db] block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute inset-y-0 right-0 pr-3 flex items-center bg-white text-gray-400 hover:text-gray-500"
                            >
                                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                            </button>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                            New Password
                        </label>
                        <input
                            type="password"
                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            className="mt-1 shadow-sm focus:ring-[#2424db] focus:border-[#2424db] px-2 py-1 bg-white block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                            Confirm New Password
                        </label>
                        <input
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            className="mt-1 shadow-sm focus:ring-[#2424db] focus:border-[#2424db] px-2 py-1 bg-white block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    {success && <p className="text-green-500 text-sm">{success}</p>}
                    <div>
                        <button
                            type="submit"
                            disabled={loading}
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#191970] hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#191970] disabled:opacity-50"
                        >
                            {loading ? 'Changing...' : 'Change Password'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChangePasswordPopup;
// hooks/useProfileOperations.js
import { useMutation, useApolloClient } from '@apollo/client';
import { UPDATE_USER_MUTATION, DELETE_CATALOG_MUTATION, DELETE_ACCOUNT_MUTATION, UPDATE_SELECTED_CATALOG, CHANGE_PASSWORD_MUTATION } from '../graphql/mutations';
import { GET_CURRENT_USER, GET_USER_CATALOGS } from '../graphql/queries';
import { toast } from 'react-toastify';

const generateUserOptimisticResponse = (userId, updates) => ({
    __typename: 'Mutation',
    updateUser: {
        __typename: 'User',
        id: userId,
        ...updates
    }
});

export const useProfileOperations = (userId) => {
    const client = useApolloClient();

    // Consolidated update user mutation
    const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
        update(cache, { data: { updateUser } }) {
            cache.modify({
                id: cache.identify({ __typename: 'User', id: userId }),
                fields: {
                    name: () => updateUser.name,
                    email: () => updateUser.email,
                }
            });
        }
    });

    // Optimized catalog deletion
    const [deleteCatalog] = useMutation(DELETE_CATALOG_MUTATION, {
        // Optimistic UI update
        optimisticResponse: (variables) => ({
            __typename: 'Mutation',
            deleteCatalog: {
                __typename: 'DeleteCatalogResponse',
                success: true,
                message: 'Catalog deleting...',
                catalogId: variables.id,
                deletedCategories: 0,
                deletedItems: 0,
                deletedImages: 0
            }
        }),

        // Update cache immediately after mutation
        update(cache, { data }) {
            if (!data?.deleteCatalog?.success) return;

            // Read the current catalogs from cache
            const existingData = cache.readQuery({
                query: GET_USER_CATALOGS,
                variables: { userId }
            });

            if (!existingData) return;

            // Filter out the deleted catalog
            const updatedCatalogs = existingData.getUserWithCatalogs.catalogs.filter(
                catalog => catalog.id !== data.deleteCatalog.catalogId
            );

            // Write the updated catalogs back to the cache
            cache.writeQuery({
                query: GET_USER_CATALOGS,
                variables: { userId },
                data: {
                    getUserWithCatalogs: {
                        ...existingData.getUserWithCatalogs,
                        catalogs: updatedCatalogs
                    }
                }
            });
        },

        // Error handling
        onError: (error) => {
            console.error('Catalog deletion error:', error);
            toast.error('Failed to delete catalog. Please try again.');
        }
    });

    const [deleteAccount] = useMutation(DELETE_ACCOUNT_MUTATION, {
        update(cache) {
            // Clear all queries related to the user's data
            cache.reset();
        },
        onCompleted: (data) => {
            if (data.deleteAccount.success) {
                // Clear authentication state
                // Assuming you have a way to clear auth tokens/user state
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');

                // Redirect or trigger logout logic
                window.location.href = '/';
            }
        },
        onError: (error) => {
            console.error('Account deletion error:', error);
            toast.error('Failed to delete account. Please try again.');
        }
    });

    // Optimized catalog selection
    const [updateSelectedCatalog] = useMutation(UPDATE_SELECTED_CATALOG, {
        update(cache, { data: { updateSelectedCatalog } }) {
            if (updateSelectedCatalog) {
                cache.modify({
                    id: cache.identify({ __typename: 'User', id: userId }),
                    fields: {
                        selectedCatalog(existingRef, { readField }) {
                            return existingRef;
                        }
                    }
                });
            }
        }
    });

    const optimizedUpdateUser = async (updates) => {
        try {
            return await updateUser({
                variables: { id: userId, ...updates },
                optimisticResponse: generateUserOptimisticResponse(userId, updates)
            });
        } catch (error) {
            console.error('Error updating user:', error);
            throw error;
        }
    };

    const optimizedDeleteCatalog = async (catalogId) => {
        try {
            const result = await deleteCatalog({
                variables: { id: catalogId }
            });

            if (result.data?.deleteCatalog?.success) {
                toast.success('Catalog deleted successfully');
                return result;
            }
        } catch (error) {
            console.error('Error deleting catalog:', error);
            throw error;
        }
    };

    // Optimized delete account function
    const optimizedDeleteAccount = async () => {
        try {
            const response = await deleteAccount();

            // Log detailed deletion results
            if (response.data?.deleteAccount) {
                console.log('Deletion Details:', {
                    catalogsDeleted: response.data.deleteAccount.deletedCatalogs,
                    categoriesDeleted: response.data.deleteAccount.deletedCategories,
                    itemsDeleted: response.data.deleteAccount.deletedItems,
                    imagesDeleted: response.data.deleteAccount.deletedImages
                });
            }

            return response;
        } catch (error) {
            // Rethrow to allow component-level error handling
            throw error;
        }
    };


    const optimizedSelectCatalog = async (catalogId) => {
        try {
            const result = await updateSelectedCatalog({
                variables: { userId, catalogId },
                optimisticResponse: {
                    __typename: 'Mutation',
                    updateSelectedCatalog: true
                }
            });
            return result;
        } catch (error) {
            console.error('Error updating selected catalog:', error);
            throw error;
        }
    };

    return {
        optimizedUpdateUser,
        optimizedDeleteCatalog,
        optimizedDeleteAccount,
        optimizedSelectCatalog
    };
};
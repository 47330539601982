import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useAuth } from '../utils/authContext';
import Layout from '../components/Layout';
import { Save, Plus, Trash2, ChevronDown, ChevronUp, Eye, Grid, ListIcon, LayoutGrid, LayoutList } from 'lucide-react';
import { UPDATE_CATALOG } from '../graphql/mutations';
import { GET_CATALOG, GET_CATALOG_BY_NAME } from '../graphql/queries';
import LoadingSpinner from '../components/LoadingSpinner';
import { defaultTheme, Modern, Classic, Blueberry, Lavender, Forest } from '../../../customer-view/src/styles/themes';
import ThemeThumbnail from '../components/ThemeThumbnail';
import CatalogPreview from '../components/CatalogPreview';
import SocialMediaSection from '../components/SocialMediaSection';
import ThemeSelector from '../components/ThemeSelector';
import FullPagePreview from '../components/FullPagePreview';
import { validatePhoneNumber } from '../utils/validateNumber';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { Switch, FormControlLabel, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTour } from '../hooks/useTour';
import { businessCategories } from '../data/businessInfo';
import { useCatalogMutations } from '../hooks/useCatalogMutations.js';
import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any';

const THEMES = ['Classic', 'Modern', 'Lavender', 'Blueberry', 'Forest', 'Taupe'];


// Main Form Component
const BrandInfoForm = ({ catalogData, setCatalogData, logoPreview, setLogoPreview, catalogId, optimizedUpdate }) => {
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const [isCompressing, setIsCompressing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSocialMediaChange = useCallback((platform, value) => {
        setCatalogData(prev => ({
            ...prev,
            socialMedia: {
                ...prev.socialMedia,
                [platform]: value
            }
        }));
    }, [setCatalogData]);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setCatalogData(prev => ({ ...prev, [name]: value }));
    }, [setCatalogData]);

    const handleLogoChange = useCallback(async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setIsCompressing(true);
        setErrorMessage('');

        try {
            // Check file size (max 100MB)
            if (file.size > 100 * 1024 * 1024) {
                throw new Error('File size exceeds 100MB. Please choose a smaller image.');
            }

            let processedFile = file;

            // Handle HEIC/HEIF formats
            if (file.type === 'image/heic' || file.type === 'image/heif') {
                try {
                    const jpegBlob = await heic2any({
                        blob: file,
                        toType: 'image/jpeg',
                        quality: 0.8
                    });
                    processedFile = new File([jpegBlob], file.name.replace(/\.(heic|heif)$/i, '.jpg'), {
                        type: 'image/jpeg'
                    });
                } catch (heicError) {
                    throw new Error('Unable to convert HEIC image. Please try a different format.');
                }
            }

            // Validate image type
            if (!processedFile.type.startsWith('image/')) {
                throw new Error('Invalid file type. Please upload an image file.');
            }

            // Compression options
            const options = {
                maxSizeMB: 4,
                maxWidthOrHeight: 1600,
                useWebWorker: true,
            };

            const compressedFile = await imageCompression(processedFile, options);

            const reader = new FileReader();
            reader.onloadend = async () => {
                try {
                    const base64String = reader.result.split(',')[1];
                    // Update the catalog with the compressed image
                    await optimizedUpdate({
                        id: catalogId,
                        logo: base64String
                    });

                    // Update preview
                    setLogoPreview(reader.result);
                    setIsCompressing(false);
                } catch (error) {
                    console.error('Error updating logo:', error);
                    setErrorMessage('Failed to update logo. Please try again.');
                    setIsCompressing(false);
                }
            };

            reader.onerror = () => {
                setErrorMessage('Error reading file. Please try again.');
                setIsCompressing(false);
            };

            reader.readAsDataURL(compressedFile);

        } catch (error) {
            console.error('Error processing image:', error);
            setErrorMessage(error.message || 'An error occurred while processing the image.');
            setIsCompressing(false);
        }
    }, [optimizedUpdate, catalogId, setLogoPreview]);



    // Handle manual toggle changes
    const handleAllowCheckoutChange = useCallback((event) => {
        setCatalogData(prev => ({ ...prev, allowCheckout: event.target.checked }));
    }, [setCatalogData]);

    // Automatically disable allowCheckout when phone is removed
    useEffect(() => {
        if (isCheckoutDisabled && catalogData.allowCheckout) {
            setCatalogData(prev => ({ ...prev, allowCheckout: false }));
        }
    }, [catalogData.phone, catalogData.allowCheckout, setCatalogData]);

    const isCheckoutDisabled = !catalogData.phone;

    return (
        <div className="space-y-4">

            {/* Logo Section */}
            <div className="flex flex-col items-center">
                {logoPreview || catalogData.logo ? (
                    <img
                        src={logoPreview || catalogData.logo}
                        alt="Logo Preview"
                        className="w-32 h-32 object-cover rounded-full mb-2 border-2 p-1 border-gray-300"
                    />
                ) : (
                    <div className="w-32 h-32 bg-gray-200 rounded-full flex items-center justify-center mb-2 border-2 p-1 border-gray-300">
                        <span className="text-gray-500">No Logo</span>
                    </div>
                )}

                <label
                    htmlFor="logo-upload"
                    className={`cursor-pointer text-[#191970] py-1 px-2 rounded-md hover:bg-opacity-90 transition-colors duration-300 flex items-center ${isCompressing ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    {isCompressing ? (
                        <>
                            <span className="ml-2">Processing...</span>
                        </>
                    ) : (
                        <>
                            <Plus className="mr-2" size={16} />
                            <span>{catalogData.logo ? 'Change Logo' : 'Upload Logo'}</span>
                        </>
                    )}
                </label>

                <input
                    id="logo-upload"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleLogoChange}
                    disabled={isCompressing}
                />

                {errorMessage && (
                    <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
                )}
            </div>


            {['name', 'description', 'phone'].map((field) => (
                <div key={field}>
                    <label htmlFor={field} className="block text-gray-700 font-bold mb-2">
                        {field.charAt(0).toUpperCase() + field.slice(1)}
                    </label>
                    {field === 'description' ? (
                        <div>
                            <textarea
                                id={field}
                                className="w-full p-2 border bg-white rounded"
                                placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                                name={field}
                                maxLength={300}
                                value={catalogData[field] || ''}
                                onChange={handleInputChange}
                            />
                            <p className="mt-1 text-sm text-gray-500">
                                {(catalogData[field] || '').length}/300 characters
                            </p>
                        </div>
                    ) : (
                        <input
                            type="text"
                            id={field}
                            className="w-full p-2 border bg-white rounded"
                            placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                            name={field}
                            maxLength={32}
                            value={catalogData[field]}
                            onChange={handleInputChange}
                            onBlur={(e) => {
                                const phoneNumber = e.target.value;
                                setIsPhoneNumberValid(validatePhoneNumber(phoneNumber));
                            }}
                        />
                    )}
                    {field === 'phone' && !isPhoneNumberValid && (
                        <p className="text-red-500 mt-1">Please enter a valid phone number</p>
                    )}
                </div>
            ))}

            {/* allowCheckout section */}

            <FormControlLabel
                control={
                    <Switch
                        checked={catalogData.allowCheckout}
                        onChange={handleAllowCheckoutChange}
                        disabled={isCheckoutDisabled}
                        color="primary"
                    />
                }
                label="Allow Checkout"
            />
            <Tooltip title="Enabling this allows customers to place orders via WhatsApp. A phone number is required for this feature.">
                <IconButton>
                    <InfoIcon fontSize="small" />
                </IconButton>
            </Tooltip>

            {isCheckoutDisabled && (
                <p className="text-sm text-gray-500">Add a phone number to enable checkout.</p>
            )}

            {/* Social Media Section */}
            <div>
                <h3 className="text-lg font-bold mb-2">Social Media</h3>
                <div className="space-y-2">
                    {[
                        { platform: 'instagram', icon: FaInstagram },
                        { platform: 'facebook', icon: FaFacebook }
                    ].map(({ platform, icon: Icon }) => (
                        <div key={platform} className="flex items-center">
                            <label htmlFor={platform} className="flex items-center text-gray-700 font-bold mr-1 w-10">
                                <Icon className="w-6 h-6" />
                            </label>
                            <input
                                type="text"
                                id={platform}
                                className="w-full p-2 border bg-white rounded"
                                placeholder={`Enter ${platform} profile URL`}
                                value={catalogData.socialMedia[platform] || ''}
                                onChange={(e) => handleSocialMediaChange(platform, e.target.value)}
                            />
                        </div>
                    ))}
                </div>
            </div>


            <button
                type="submit"
                className="w-full bg-[#191970] text-white rounded-md py-2 px-4 flex items-center justify-center hover:bg-blue-700 transition-colors duration-300"
            >
                <Save className="h-5 w-5 mr-2" />
                Save Changes
            </button>


        </div>
    );
};

// Main Component
const Personalization = () => {
    const { user, catalogs, refetchUserData, selectedCatalog } = useAuth();
    const [logoPreview, setLogoPreview] = useState(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState(Classic);
    const [viewMode, setViewMode] = useState('grid');
    const [isCompressing, setIsCompressing] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [catalogData, setCatalogData] = useState({
        name: '',
        description: '',
        logo: '',
        phone: '',
        socialMedia: {},
        theme: '',
        allowCheckout: false,
    });

    const catalogId = useMemo(() => user?.selectedCatalog?.id || catalogs[0]?.id, [user, catalogs]);
    const catalogName = useMemo(() => user?.selectedCatalog?.catalogName || catalogs[0]?.catalogName, [user, catalogs]);


    const { optimizedUpdate } = useCatalogMutations(catalogData);

    const { loading, error, data, refetch } = useQuery(GET_CATALOG, {
        variables: { id: catalogId },
        skip: !catalogId,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const { loading: catalogLoading, error: catalogError, data: catalogPreviewData, refetch: refetchCatalogPreview } = useQuery(GET_CATALOG_BY_NAME, {
        variables: { catalogName },
        skip: !catalogName,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const { startTour } = useTour('personalization');

    // product tour
    useEffect(() => {
        if (selectedCatalog?.setupCompleted && !user?.tourStatus?.personalization) {
            const tourKey = `${selectedCatalog.id}_personalization`;
            if (!localStorage.getItem(`${tourKey}TourComplete`)) {
                const timer = setTimeout(startTour, 500);
                return () => clearTimeout(timer);
            }
        }
    }, [selectedCatalog, user, startTour]);



    useEffect(() => {
        const fetchInitialData = async () => {
            if (refetch && refetchCatalogPreview) {
                await Promise.all([refetch(), refetchCatalogPreview()]);
            }
        };
        fetchInitialData();
    }, [refetch, refetchCatalogPreview]);

    useEffect(() => {
        if (data?.catalog) {
            const { name, description, logo, phone, socialMedia, theme, viewMode, allowCheckout } = data.catalog;
            setCatalogData({ name, description, logo, phone, socialMedia: socialMedia || {}, theme, viewMode, allowCheckout });
            setLogoPreview(logo);
            setSelectedTheme(theme || 'Classic');
            setViewMode(viewMode || 'grid');
        }
    }, [data]);

    useEffect(() => {
        if (catalogPreviewData?.getCatalogByName?.catalog?.theme) {
            setSelectedTheme(catalogPreviewData.getCatalogByName.catalog.theme);
        }
        if (catalogPreviewData?.getCatalogByName?.catalog?.viewMode) {
            setViewMode(catalogPreviewData.getCatalogByName.catalog.viewMode);
        }
    }, [catalogPreviewData]);




    const handleThemeSelect = useCallback(async (themeName) => {
        setSelectedTheme(themeName);
        try {
            await optimizedUpdate({
                id: catalogId,
                theme: themeName
            }, {
                ...catalogData,
                theme: themeName
            });
        } catch (error) {
            console.error("Error updating theme:", error);
            setSelectedTheme(catalogData.theme); // Revert on error
        }
    }, [catalogId, catalogData, optimizedUpdate]);

    const handleViewModeChange = useCallback(async (mode) => {
        setViewMode(mode);
        try {
            await optimizedUpdate({
                id: catalogId,
                viewMode: mode
            }, {
                ...catalogData,
                viewMode: mode
            });
        } catch (error) {
            console.error("Error updating view mode:", error);
            setViewMode(catalogData.viewMode); // Revert on error
        }
    }, [catalogId, catalogData, optimizedUpdate]);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();

        try {
            const preparedSocialMedia = Object.entries(catalogData.socialMedia)
                .reduce((acc, [key, value]) => {
                    if (key !== '__typename' && value) {
                        acc[key] = value;
                    }
                    return acc;
                }, {});


            await optimizedUpdate({
                id: catalogId,
                name: catalogData.name,
                description: catalogData.description,
                phone: catalogData.phone,
                socialMedia: preparedSocialMedia,
                allowCheckout: catalogData.allowCheckout,
            });

        } catch (error) {
            console.error('Error updating catalog:', error);
        }
    }, [catalogId, catalogData, optimizedUpdate]);

    if (!user) return <p>Please log in to view this page.</p>;


    // if (!catalogId) {refetchUserData();}

    if (loading || catalogLoading) return <LoadingSpinner />;
    if (error || catalogError) return <p>Error: {error?.message || catalogError?.message}</p>;

    const { catalog, categoriesWithItems, uncategorizedItems } = catalogPreviewData?.getCatalogByName || {};

    return (
        <Layout
            title="Personalization"
            description="Customize your catalog's appearance"
        >
            <div className="p-2 sm:p-4 lg:p-6 bg-gray-100 mb-10">
                <form onSubmit={handleSubmit} className="flex flex-col lg:flex-row gap-4">
                    <div className="flex-1 space-y-4 w-full lg:w-2/3">
                        <div className="brand-info bg-white rounded-lg shadow-md p-6">
                            <BrandInfoForm
                                catalogData={catalogData}
                                setCatalogData={setCatalogData}
                                logoPreview={logoPreview}
                                setLogoPreview={setLogoPreview}
                                catalogId={catalogId}
                                optimizedUpdate={optimizedUpdate}
                            />
                        </div>



                        <div className="theme-section bg-white rounded-lg shadow-md p-6">
                            <h2 className="text-2xl font-bold mb-4">Select a Theme</h2>
                            <ThemeSelector
                                selectedTheme={selectedTheme}
                                onSelectTheme={handleThemeSelect}
                            />
                        </div>

                        <div className="bg-white rounded-lg shadow-md p-6">
                            <h2 className="text-2xl font-bold mb-4">Layout</h2>
                            <div className="flex space-x-4">
                                <button
                                    type="button"
                                    className={`px-4 py-2 rounded-md ${viewMode === 'grid' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
                                    onClick={() => handleViewModeChange('grid')}
                                >
                                    <LayoutGrid /> Grid
                                </button>
                                <button
                                    type="button"
                                    className={`px-4 py-2 rounded-md ${viewMode === 'list' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
                                    onClick={() => handleViewModeChange('list')}
                                >
                                    <LayoutList />List
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="flex-1 w-full lg:w-1/3">
                        <div className="bg-white shadow-lg rounded-lg p-4 lg:sticky lg:top-4 mb-10">

                            {/* <h2 className="text-2xl font-bold mb-4">Preview</h2> */}
                            <button
                                type="button"
                                onClick={() => setIsPreviewOpen(true)}
                                className="w-full lg:hidden bg-[#191970] text-white rounded-md py-2 px-4 flex items-center justify-center hover:bg-blue-700 transition-colors duration-300"
                            >
                                <Eye className="h-5 w-5 mr-2" />
                                Preview Catalog
                            </button>
                            <div className='hidden lg:flex justify-center'>
                                {catalog && (
                                    <CatalogPreview
                                        businessInfo={{
                                            name: catalog.name,
                                            description: catalog.description,
                                            logo: catalog.logo,
                                            phone: catalog.phone,
                                            socialMedia: catalog.socialMedia,
                                            allowCheckout: catalog.allowCheckout,
                                        }}
                                        categories={categoriesWithItems}
                                        uncategorizedItems={uncategorizedItems}
                                        themeName={selectedTheme}
                                        viewMode={viewMode}
                                    />
                                )}
                            </div>

                        </div>
                    </div>
                </form>
            </div>

            <FullPagePreview
                isOpen={isPreviewOpen}
                onClose={() => setIsPreviewOpen(false)}
                catalog={data?.catalog}
                categoriesWithItems={catalogPreviewData?.getCatalogByName?.categoriesWithItems}
                uncategorizedItems={catalogPreviewData?.getCatalogByName?.uncategorizedItems}
                selectedTheme={selectedTheme}
                viewMode={viewMode}
                onThemeChange={handleThemeSelect}
            />
        </Layout>
    );
};

export default React.memo(Personalization);
import { gql } from '@apollo/client';



export const CHECK_CATALOG_NAME_AVAILABILITY = gql`
    query CheckCatalogNameAvailability($catalogName: String!) {
        checkCatalogNameAvailability(catalogName: $catalogName)
    }
`;

// Common Fragments
export const CATALOG_BASIC_FIELDS = gql`
  fragment CatalogBasicFields on Catalog {
    id
    name
    catalogName
    description
    logo
    lastUpdated
  }
`;

export const STAT_ITEM_FIELDS = gql`
  fragment StatItemFields on ItemWithClickCount {
    id
    name
    clickCount
  }
`;

// TopItem Fragment specifically for analytics
export const TOP_ITEM_FIELDS = gql`
  fragment TopItemFields on TopItem {
    id
    name
    clickCount
    percentageDifference
  }
`;

export const CATALOG_DETAILS_FIELDS = gql`
  fragment CatalogDetailsFields on Catalog {
    ...CatalogBasicFields
    phone
    socialMedia {
      facebook
      instagram
      tiktok
      email
      website
      telegram
    }
    theme
    viewMode
    allowCheckout
    onboardingStatus
    onboardingStep
    businessType
    businessCategory
    subCategories
    location
    setupCompleted
  }
  ${CATALOG_BASIC_FIELDS}
`;

export const ITEM_BASIC_FIELDS = gql`
  fragment ItemBasicFields on Item {
    id
    name
    description
    image
    order
    isVisible
    clickCount
  }
`;

export const CATEGORY_BASIC_FIELDS = gql`
  fragment CategoryBasicFields on Category {
    id
    name
    order
    isVisible
  }
`;

export const ITEM_PRICE_FIELDS = gql`
  fragment ItemPriceFields on Item {
    priceVariants {
      variant
      price
    }
  }
`;

export const CATEGORY_WITH_ITEMS_FIELDS = gql`
  fragment CategoryWithItemsFields on Category {
    id
    name
    order
    isVisible
    items {
      ...ItemBasicFields
      ...ItemPriceFields
      category
    }
  }
  ${ITEM_BASIC_FIELDS}
  ${ITEM_PRICE_FIELDS}
`;

// Optimized Queries
export const GET_CATALOG = gql`
  query GetCatalog($id: ID!) {
    catalog(id: $id) {
      ...CatalogDetailsFields
    }
  }
  ${CATALOG_DETAILS_FIELDS}
`;

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      name
      email
      isGoogleAccount
      twoFactorEnabled
      isVerified
      tourStatus {
        overview
        personalization
        statistics
        catalog
        lastCompletedAt
      }
      selectedCatalog {
        ...CatalogDetailsFields
      }
    }
  }
  ${CATALOG_DETAILS_FIELDS}
`;

export const GET_USER_CATALOGS = gql`
  query GetUserCatalogs($userId: ID!) {
    getUserWithCatalogs(userId: $userId) {
      name
      catalogs {
        ...CatalogBasicFields
      }
    }
  }
  ${CATALOG_BASIC_FIELDS}
`;

export const GET_CATALOG_ITEMS_AND_CATEGORIES = gql`
  query GetCatalogItemsAndCategories($catalogId: ID!) {
    catalogItemsAndCategories(catalogId: $catalogId) {
      categories {
        id
        name
        order
        isVisible
        items {
          ...ItemBasicFields
          ...ItemPriceFields
          category
        }
      }
      uncategorizedItems {
        ...ItemBasicFields
        ...ItemPriceFields
      }
    }
  }
  ${ITEM_BASIC_FIELDS}
  ${ITEM_PRICE_FIELDS}
`;


export const COMBINED_OVERVIEW_DATA = gql`
  query CombinedOverviewData($catalogId: ID!, $days: Int!, $limit: Int!) {
    overviewData(catalogId: $catalogId, days: $days, limit: $limit) {
      performance {
        totalCategories
        totalItems
        weeklyCatalogViews
        performancePercentageChange
      }
      topItems {
        ...TopItemFields
      }
    }
  }
  ${TOP_ITEM_FIELDS}
`;

export const GET_CATALOG_STATISTICS = gql`
  query GetCatalogStatistics($catalogId: ID!, $days: Int!) {
    getCatalogStatistics(catalogId: $catalogId, days: $days) {
      totalCategories
      totalItems
      totalItemClicks
      performancePercentageChange
      dailyVisits {
        date
        visits
      }
      topItems {
        ...StatItemFields
      }
    }
  }
  ${STAT_ITEM_FIELDS}
`;

export const GET_CATALOG_BY_NAME = gql`
  query GetCatalogByName($catalogName: String!) {
    getCatalogByName(catalogName: $catalogName) {
      catalog {
        ...CatalogBasicFields
        phone
        socialMedia {
          facebook
          instagram
          tiktok
          email
          website
          telegram
        }
        theme
        viewMode
        allowCheckout
      }
      categoriesWithItems {
        id
        name
        order
        items {
          ...ItemBasicFields
          ...ItemPriceFields
        }
      }
      uncategorizedItems {
        ...ItemBasicFields
        ...ItemPriceFields
      }
    }
  }
  ${CATALOG_BASIC_FIELDS}
  ${ITEM_BASIC_FIELDS}
  ${ITEM_PRICE_FIELDS}
`;

// Analytics Queries
export const CATALOG_PERFORMANCE = gql`
  query CatalogPerformance($catalogId: ID!, $days: Int!) {
    catalogPerformance(catalogId: $catalogId, days: $days) {
      totalCategories
      totalItems
      totalItemClicks
      performancePercentageChange
      __typename
    }
  }
`;

export const TOP_ITEMS_BY_CLICKS = gql`
  query TopItemsByClicks($catalogId: ID!, $limit: Int!, $previousDays: Int!) {
    topItemsByClicks(catalogId: $catalogId, limit: $limit, previousDays: $previousDays) {
      ...TopItemFields
    }
  }
  ${TOP_ITEM_FIELDS}
`;
export const GET_VENDOR_FAQS = gql`
    query GetVendorFAQs {
      vendorFAQs {
        id
        question
        answer
      }
    }
  `;
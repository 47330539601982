// TopItems.js
import React from 'react';
import { ArrowUpIcon, ArrowDownIcon } from 'lucide-react';

const TopItems = ({ items }) => {
    return (
        <div className="lg:bg-white rounded-lg lg:shadow-lg p-2 sm:p-6">
            <h2 className="text-xl font-bold mb-2">Activity Log</h2>
            <p className="mb-4 text-sm">Based on top items by clicks <span className='text-xs'>(Weekly)</span></p>
            <ul className="space-y-2">
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        <li className="flex justify-between items-center py-2">
                            <span className="text-sm">{item.name}</span>
                            <div className="flex items-center">
                                {item.clickCount > 0 ? (
                                    <ArrowUpIcon className="w-3 h-3 sm:w-4 sm:h-4 text-green-500 mr-1" />
                                ) : (
                                    <ArrowDownIcon className="w-3 h-3 sm:w-4 sm:h-4 text-red-500 mr-1" />
                                )}
                                <span className={`text-xs sm:text-sm ${item.clickCount > 0 ? 'text-green-500' : 'text-red-500'}`}>
                                    {item.clickCount}
                                </span>
                            </div>
                        </li>
                        {index < items.length - 1 && <hr className="border-gray-200" />}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
};

export default React.memo(TopItems);
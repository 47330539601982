import { ApolloClient, InMemoryCache, createHttpLink, from, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { TokenManager } from '../utils/tokenManager';

const PUBLIC_GRAPHQL_URL = import.meta.env.VITE_PUBLIC_GRAPHQL_URL;

// Define entity key fields for cache normalization
const possibleTypes = {
    CatalogInterface: ['Catalog'],
    ItemInterface: ['Item'],
    CategoryInterface: ['Category']
};

// Create cache configuration with type policies
const cache = new InMemoryCache({
    possibleTypes,
    typePolicies: {
        Query: {
            fields: {
                // Cache configuration for catalog queries
                catalogItemsAndCategories: {
                    merge(existing, incoming) {
                        return incoming; // Always use latest data for catalog structure
                    },
                    // Read function to serve cached data
                    read(existing) {
                        return existing;
                    }
                },
                // Paginated fields like items
                items: {
                    // Custom merge function for items
                    merge(existing = [], incoming) {
                        return [...existing, ...incoming];
                    },
                    // Read from cache first
                    read(existing) {
                        return existing;
                    }
                },
                // Analytics data caching
                overviewData: {
                    merge: true, // Keep existing data and merge new fields
                }
            }
        },
        Catalog: {
            keyFields: ['id'],
            fields: {
                lastUpdated: {
                    merge: true,
                },
                viewCount: {
                    // Optimize view count updates
                    merge(existing = 0, incoming) {
                        return Math.max(existing, incoming);
                    }
                }
            }
        },
        Category: {
            keyFields: ['id'],
            fields: {
                items: {
                    merge(existing = [], incoming) {
                        return incoming; // Categories need latest item order
                    }
                }
            }
        },
        Item: {
            keyFields: ['id'],
            fields: {
                clickCount: {
                    // Optimize click count updates
                    merge(existing = 0, incoming) {
                        return Math.max(existing, incoming);
                    }
                },
                order: {
                    merge(existing, incoming) {
                        return incoming ?? existing;
                    }
                }
            }
        },
        User: {
            keyFields: ['id'],
            fields: {
                selectedCatalog: {
                    merge: true,
                }
            }
        }
    },
    dataIdFromObject: object => {
        // Custom cache ID generation based on __typename and id fields
        switch (object.__typename) {
            case 'Catalog': return `Catalog:${object.id}`;
            case 'Category': return `Category:${object.id}`;
            case 'Item': return `Item:${object.id}`;
            case 'User': return `User:${object.id}`;
            default: return null;
        }
    }
});

const createClient = () => {
    const httpLink = createHttpLink({
        uri: PUBLIC_GRAPHQL_URL,
        // Enable automatic persisted queries
        useGETForQueries: true
    });

    const authLink = setContext(async (_, { headers }) => {
        const token = TokenManager.getAccessToken();
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    // Enhanced error handling with retry logic
    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                switch (err.extensions.code) {
                    case 'UNAUTHENTICATED':
                        return new Observable(observer => {
                            TokenManager.refreshTokens()
                                .then(success => {
                                    if (success) {
                                        // Retry with new token
                                        const oldHeaders = operation.getContext().headers;
                                        operation.setContext({
                                            headers: {
                                                ...oldHeaders,
                                                authorization: `Bearer ${TokenManager.getAccessToken()}`,
                                            },
                                        });
                                        observer.next(forward(operation));
                                    } else {
                                        window.location.href = '/login';
                                        observer.error(err);
                                    }
                                })
                                .catch(error => {
                                    console.error('Token refresh failed:', error);
                                    observer.error(error);
                                });
                        });
                }
            }
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    return new ApolloClient({
        link: from([errorLink, authLink, httpLink]),
        cache,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'cache-and-network',
                errorPolicy: 'all',
                nextFetchPolicy: 'cache-first',
            },
            query: {
                fetchPolicy: 'cache-first',
                errorPolicy: 'all',
            },
            mutate: {
                errorPolicy: 'all',
            },
        },
        connectToDevTools: process.env.NODE_ENV === 'development',
        queryDeduplication: true,
    });
};

let client = createClient();

export const resetClient = () => {
    client = createClient();
};

export default client;
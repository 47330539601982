// Register.jsx
import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { REGISTER_USER, GOOGLE_SIGN_IN, SEND_VERIFICATION_EMAIL } from '../graphql/mutations';
import AuthLayout from '../components/AuthLayout';
import debounce from 'lodash/debounce';
import { setToken } from '../utils/auth';
import { useLazyQuery } from '@apollo/client';
import { CHECK_CATALOG_NAME_AVAILABILITY, } from '../graphql/queries';
import { useGoogleLogin } from '@react-oauth/google';
// import jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import { useAuth } from '../utils/authContext';

const Register = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [catalogName, setCatalogName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [catalogNameError, setCatalogNameError] = useState('');
    const [isCatalogNameAvailable, setIsCatalogNameAvailable] = useState(null);
    const [suggestedCatalogNames, setSuggestedCatalogNames] = useState([]);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');


    const { login } = useAuth();
    const [registerUser, { loading, error }] = useMutation(REGISTER_USER);
    const [sendVerificationEmail] = useMutation(SEND_VERIFICATION_EMAIL);

    const [checkCatalogNameAvailability] = useLazyQuery(CHECK_CATALOG_NAME_AVAILABILITY);
    const [name, setName] = useState('');


    const validateCatalogName = (catalogName) => {
        if (catalogName.includes(' ')) {
            return 'Catalog name cannot contain spaces';
        }
        if (catalogName.length < 3 || catalogName.length > 32) {
            return 'Catalog name must be between 3 and 32 characters';
        }
        if (!/^[a-zA-Z0-9_-]+$/.test(catalogName)) {
            return 'Catalog name can only contain letters, numbers, underscores, and hyphens';
        }
        return null; // No error
    };

    const checkAvailability = useCallback(
        debounce(async (catalogName) => {
            try {
                const { data, error } = await checkCatalogNameAvailability({ variables: { catalogName } });
                if (error) {
                    console.error('GraphQL error:', error);
                    setIsCatalogNameAvailable(false);
                    return;
                }
                if (data && typeof data.checkCatalogNameAvailability === 'boolean') {
                    setIsCatalogNameAvailable(data.checkCatalogNameAvailability);
                    if (!data.checkCatalogNameAvailability) {
                        const suggestions = [
                            `${catalogName}123`,
                            `${catalogName}_catalog`,
                            `the_${catalogName}`,
                            `${catalogName}_official`,
                        ];
                        setSuggestedCatalogNames(suggestions);
                    } else {
                        setSuggestedCatalogNames([]);
                    }
                } else {
                    console.error('Unexpected data format:', data);
                    setIsCatalogNameAvailable(false);
                }
            } catch (error) {
                console.error('Error checking catalog name availability:', error);
                setIsCatalogNameAvailable(false);
            }
        }, 300),
        [checkCatalogNameAvailability]
    );

    const handleCatalogNameChange = (e) => {
        const newCatalogName = e.target.value.replace(/\s+/g, '');
        setCatalogName(newCatalogName);
        const error = validateCatalogName(newCatalogName);
        setCatalogNameError(error);
        if (!error) {
            checkAvailability(newCatalogName);
        } else {
            setIsCatalogNameAvailable(null);
            setSuggestedCatalogNames([]);
        }
    };

    const handleFirstStepSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        if (password.length < 6 || (!/^(?=.*[A-Z])/.test(password)) || (!/^(?=.*[a-z])/.test(password))) {
            setErrorMessage('Your new password must be at least 6 characters long and contain both uppercase and lowercase letters.');
            return;
        }

        // if (!/^(?=.*[A-Z])/.test(password)) {
        //     setErrorMessage('Password must contain at least one uppercase letter');
        //     return;
        // }

        // if (!/^(?=.*[a-z])/.test(password)) {
        //     setErrorMessage('Password must contain at least one lowercase letter');
        //     return;
        // }

        // if
        //     (!/^(?=.*\d)/.test(password)) {
        //     setErrorMessage('Password must contain at least one number');
        //     return;
        // }


        setStep(2);
    };

    const handleFinalSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await registerUser({
                variables: { name, email, password, businessName, catalogName }
            });
            if (data.register) {
                // Instead of logging in immediately, send verification email
                await sendVerificationEmail({ variables: { email } });
                // navigate('/verify', { state: { email } });


                const { accessToken, refreshToken, user, catalog } = data.register;
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                login({ ...user, selectedCatalog: catalog });
                navigate('/overview');

            }
        } catch (error) {
            console.error('Registration error:', error);

            if (error.graphQLErrors) {
                error.graphQLErrors.forEach(({ message, extensions }) => {
                    setErrorMessage(message);

                    console.error('GraphQL error:', message);
                    console.error('Error details:', extensions);
                });
            }
            if (error.networkError) {
                console.error('Network error:', error.networkError);
            }
        }
    };


    const handleBack = () => {
        setStep(1);
        setErrorMessage('');
    };

    return (
        <AuthLayout
            title={step === 1 ? "Create an Account" : "Choose Your Store Link"}
            description={step === 1 ? "Join us today and get started" : "This will be the web address of your catalog"}
        // imageSrc="banner1.svg"
        >
            {step === 1 ? (
                <form onSubmit={handleFirstStepSubmit}>
                    {/* In your first step form: */}
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Your Name"
                            className="w-full px-3 py-2 border bg-white text-black border-gray-300 rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db]"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="example@domain.com"
                            className="w-full px-3 py-2 border border-gray-300 bg-white text-black rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db]"
                            required
                        />
                    </div>
                    <div className="mb-4 relative">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">Password</label>
                        <div className="relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="*************"
                                className="w-full px-3 py-2 pr-10 border border-gray-300 bg-white text-black rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db]"
                                required
                            />
                            <button
                                type="button"
                                className="absolute top-1 right-2 flex items-center px-3 py-2 bg-gray-50"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-gray-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-gray-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                    {errorMessage && <p className="my-4 text-red-500 text-sm text-center">{
                        errorMessage}</p>}

                    <button type="submit" className="w-full bg-[#191970] text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-300">Continue</button>
                </form>
            ) : (
                <form onSubmit={handleFinalSubmit}>

                    <div className="mb-4">
                        <button
                            type="button"
                            onClick={handleBack}
                            className="text-[#191970] hover:underline bg-gray-50 flex items-center"
                        >
                            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                            </svg>
                            Back
                        </button>
                    </div>

                    <div className="mb-6">
                        <label htmlFor="businessName" className="block text-sm font-medium text-gray-700 mb-1">Store Name</label>
                        <input
                            type="text"
                            id="businessName"
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                            placeholder="Mary's Bakery"
                            className="w-full px-3 py-2 mb-5 border bg-white text-black border-gray-300 rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db]"
                            required
                        />
                        <label htmlFor="catalogName" className="block text-sm font-medium  text-gray-700 mb-1">Store Link</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <span className="absolute inset-y-0 left-0 pl-3 flex items-center  text-gray-500 text-sm lg:text-base">
                                pricilog.com/
                            </span>
                            <input
                                type="text"
                                id="catalogName"
                                className="w-full pl-[78px] pr-7 py-2 border bg-white border-gray-300 rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db] text-sm lg:text-base lg:pl-[86px]"
                                placeholder="marys-bakery"
                                value={catalogName}
                                onChange={handleCatalogNameChange}
                            />
                            {isCatalogNameAvailable !== null && (
                                <span className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                    {isCatalogNameAvailable ? (
                                        <svg className="h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                        </svg>
                                    ) : (
                                        <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                        </svg>
                                    )}
                                </span>
                            )}
                        </div>
                        {catalogNameError && <p className="mt-2 text-sm text-red-600">{catalogNameError}</p>}

                        <p className="text-xs text-gray-500 mt-2">
                            This will be your unique web address. Use your business name without spaces or special  .
                            For example, if your business is "Mary's Bakery", you might use "marysbakery".
                        </p>
                        {!catalogNameError && (
                            <p className="mt-2 text-xs text-gray-500">
                                Your catalog will be accessible at <span className='text-[#191970]'>www.pricilog.com/{catalogName || 'marys-bakery'} </span>
                            </p>
                        )}
                    </div>


                    {suggestedCatalogNames.length > 0 && (
                        <div className="mb-6">
                            <p className="text-sm font-medium text-gray-700 mb-2">Suggested catalog names:</p>
                            <ul className="space-y-1">
                                {suggestedCatalogNames.map((suggestedName, index) => (
                                    <li key={index}>
                                        <button
                                            type="button"
                                            className="text-[#191970] bg-gray-50 hover:underline"
                                            onClick={() => setCatalogName(suggestedName)}
                                        >
                                            {suggestedName}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {errorMessage && <p className="my-4 text-red-500 text-sm text-center">{
                        errorMessage}</p>}

                    <button
                        type="submit"
                        className="w-full bg-[#191970] text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={!isCatalogNameAvailable || !!catalogNameError || loading}
                    >
                        {loading ? 'Registering...' : 'Complete Registration'}
                    </button>
                </form>
            )}

            {step === 1 && (
                <>
                    {/* <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300"></div>
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-gray-100 text-gray-500">Or sign up with</span>
                            </div>
                        </div>

                        <div className="mt-6">
                            <button
                                onClick={() => googleLogin()}
                                className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                            >
                                <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" className="w-5 h-5 mr-2" />
                                Sign up with Google
                            </button>
                        </div>
                    </div> */}

                    <p className="mt-8 text-center text-sm text-gray-600">
                        Already have an account?{' '}
                        <Link to="/login" className="font-medium text-[#191970] hover:underline">Login</Link>
                    </p>
                </>
            )}

        </AuthLayout>
    );
};

export default Register;
import React from 'react';
import { Link } from 'react-router-dom';

const PerformanceMetrics = ({ data }) => {
    const metrics = [
        {
            title: 'Categories',
            value: data.totalCategories || 0,
            icon: '📁',
            link: `/catalog`
        },
        {
            title: 'Items',
            value: data.totalItems || 0,
            icon: '📦',
            link: `/catalog`
        },
        {
            title: 'Weekly Views',
            value: data.weeklyCatalogViews || 0,
            icon: '👀',
            link: `/statistics`
        },
        {
            title: 'Performance (Weekly)',
            value: `${data.performancePercentageChange?.toFixed(2) || 0}%`,
            icon: '📈',
            link: `/statistics`
        },
    ];

    return (
        <div className="lg:bg-white rounded-lg lg:shadow-lg p-2 sm:p-6">
            <h2 className="text-xl font-bold mb-2">Overall Analytics</h2>
            <div className="w-16 h-1 bg-blue-500 mb-4"></div>
            <div className="h-full flex flex-col">
                <p className="text-gray-600 mb-4 text-sm">Here's a summary of your catalog's performance</p>
                <div className="grid grid-cols-2 gap-3 sm:gap-4">
                    {metrics.map((item, index) => (
                        <div key={index} className="bg-white lg:bg-gray-100 rounded-lg shadow p-2 sm:p-3 flex flex-col justify-between">
                            <Link
                                to={item.link}
                                className="text-2xl sm:text-3xl mb-1 hover:opacity-70 transition-opacity duration-200"
                            >
                                {item.icon}
                            </Link>
                            <Link
                                to={item.link}
                                className="text-lg sm:text-xl text-black font-bold hover:underline"
                            >
                                {item.value}
                            </Link>
                            <div className="text-xs sm:text-sm text-gray-500">{item.title}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default React.memo(PerformanceMetrics);
// src/data/
export const nigerianStates = [
    "Abia", "Adamawa", "Akwa Ibom", "Anambra", "Bauchi", "Bayelsa", "Benue",
    "Borno", "Cross River", "Delta", "Ebonyi", "Edo", "Ekiti", "Enugu", "FCT",
    "Gombe", "Imo", "Jigawa", "Kaduna", "Kano", "Katsina", "Kebbi", "Kogi",
    "Kwara", "Lagos", "Nasarawa", "Niger", "Ogun", "Ondo", "Osun", "Oyo",
    "Plateau", "Rivers", "Sokoto", "Taraba", "Yobe", "Zamfara"
];


export const businessCategories = {
    "Agriculture": [
        "Farm Produce", "Poultry", "Fish Farming", "Agricultural Supplies",
        "Animal Feed", "Seeds & Seedlings"
    ],
    "Beauty & Personal Care": [
        "Hair Salon", "Barbing Salon", "Spa & Massage", "Skincare Products",
        "Cosmetics", "Hair Products", "Perfumes", "Natural/Organic Products"
    ],
    "Electronics & Gadgets": [
        "Phones & Accessories", "Computers & Laptops", "Electronics Repair",
        "Gaming & Consoles", "Home Electronics", "Security Gadgets"
    ],
    "Fashion & Clothing": [
        "Women's Fashion", "Men's Fashion", "Children's Wear", "Traditional Attire",
        "Fashion Accessories", "Shoes & Footwear", "Bags & Leather Goods", "Jewelry"
    ],
    "Food & Beverages": [
        "Restaurant", "Fast Food", "Catering Services", "Bakery",
        "Small Chops", "Drinks & Beverages", "Food Ingredients", "Fresh Produce"
    ],
    "Health & Wellness": [
        "Pharmacy", "Herbal Products", "Fitness & Gym", "Medical Supplies",
        "Dental Services", "Nutritional Products"
    ],
    "Home & Living": [
        "Furniture", "Home Decor", "Kitchen & Dining", "Bedding & Textiles",
        "Home Maintenance", "Home Appliances"
    ],
    "Services": [
        "Event Planning", "Photography/Videography", "Cleaning Services",
        "Laundry Services", "Car Wash", "Printing Services", "Business Services"
    ],
    "Others": []
};
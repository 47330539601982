import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useLazyQuery, useApolloClient } from '@apollo/client';
import { CREATE_CATALOG, UPDATE_SELECTED_CATALOG } from '../graphql/mutations';
import { CHECK_CATALOG_NAME_AVAILABILITY, GET_USER_CATALOGS, GET_CURRENT_USER } from '../graphql/queries';
import AuthLayout from './AuthLayout';
import debounce from 'lodash/debounce';
import { useAuth } from '../utils/authContext';

const CatalogSetup = () => {
    const [name, setName] = useState('');
    const [catalogName, setCatalogName] = useState('');
    const [catalogNameError, setCatalogNameError] = useState('');
    const [isCatalogNameAvailable, setIsCatalogNameAvailable] = useState(null);
    const [suggestedCatalogNames, setSuggestedCatalogNames] = useState([]);

    const navigate = useNavigate();
    const { refetchUserData } = useAuth(); // Get refetchUserData from useAuth

    const client = useApolloClient();

    const [createCatalog, { loading: createLoading, error: createError }] = useMutation(CREATE_CATALOG, {
        update: (cache, { data: { createCatalog } }) => {
            // Update GET_USER_CATALOGS cache
            try {
                const existingCatalogs = cache.readQuery({
                    query: GET_USER_CATALOGS,
                    variables: { userId: createCatalog.user }
                });

                if (existingCatalogs) {
                    cache.writeQuery({
                        query: GET_USER_CATALOGS,
                        variables: { userId: createCatalog.user },
                        data: {
                            getUserWithCatalogs: {
                                ...existingCatalogs.getUserWithCatalogs,
                                catalogs: [
                                    ...existingCatalogs.getUserWithCatalogs.catalogs,
                                    {
                                        __typename: 'Catalog',
                                        id: createCatalog.id,
                                        catalogName: createCatalog.catalogName,
                                        name: createCatalog.name,
                                        logo: null,
                                        description: null
                                    }
                                ]
                            }
                        }
                    });
                }

                // Update GET_CURRENT_USER cache to include the new selected catalog
                const existingUser = cache.readQuery({
                    query: GET_CURRENT_USER
                });

                if (existingUser) {
                    cache.writeQuery({
                        query: GET_CURRENT_USER,
                        data: {
                            getCurrentUser: {
                                ...existingUser.getCurrentUser,
                                selectedCatalog: {
                                    __typename: 'Catalog',
                                    id: createCatalog.id,
                                    catalogName: createCatalog.catalogName,
                                    name: createCatalog.name,
                                    logo: null,
                                    description: null,
                                    onboardingStatus: 'NOT_STARTED',
                                    onboardingStep: 0,
                                    businessType: null,
                                    businessCategory: null,
                                    subCategories: [],
                                    location: null,
                                    allowCheckout: false,
                                    setupCompleted: false
                                }
                            }
                        }
                    });
                }
            } catch (error) {
                console.error('Error updating cache:', error);
            }
        },
        onCompleted: async (data) => {
            try {
                // Update selected catalog in localStorage
                localStorage.setItem('selectedCatalogId', data.createCatalog.id);

                // Navigate to overview page
                navigate('/overview');
            } catch (error) {
                console.error('Error in onCompleted:', error);
            }
        }
    });


    const [updateSelectedCatalog] = useMutation(UPDATE_SELECTED_CATALOG, {
        update: (cache, { data: { updateSelectedCatalog } }) => {
            if (updateSelectedCatalog) {
                // Clear specific queries from cache to force refetch
                cache.evict({ fieldName: 'catalogItemsAndCategories' });
                cache.evict({ fieldName: 'catalogPerformance' });
                cache.gc();
            }
        }
    });

    const [checkCatalogNameAvailability] = useLazyQuery(CHECK_CATALOG_NAME_AVAILABILITY);

    const handleBack = () => {
        navigate('/profile');
    };

    const validateCatalogName = (name) => {
        if (name.includes(' ')) {
            return 'Catalog name cannot contain spaces';
        }
        if (name.length < 3 || name.length > 32) {
            return 'Catalog name must be between 3 and 32 characters';
        }
        if (!/^[a-zA-Z0-9_-]+$/.test(name)) {
            return 'Catalog name can only contain letters, numbers, underscores, and hyphens';
        }
        return null; // No error
    };

    const checkAvailability = useCallback(
        debounce(async (name) => {
            try {
                const { data } = await checkCatalogNameAvailability({ variables: { catalogName: name } });
                setIsCatalogNameAvailable(data.checkCatalogNameAvailability);
                if (!data.checkCatalogNameAvailability) {
                    const suggestions = [
                        `${name}123`,
                        `${name}_catalog`,
                        `the_${name}`,
                        `${name}_official`,
                    ];
                    setSuggestedCatalogNames(suggestions);
                } else {
                    setSuggestedCatalogNames([]);
                }
            } catch (error) {
                console.error('Error checking catalog name availability:', error);
                setIsCatalogNameAvailable(false);
            }
        }, 300),
        [checkCatalogNameAvailability]
    );

    const handleCatalogNameChange = (e) => {
        const newCatalogName = e.target.value.replace(/\s+/g, '');
        setCatalogName(newCatalogName);
        const error = validateCatalogName(newCatalogName);
        setCatalogNameError(error);
        if (!error) {
            checkAvailability(newCatalogName);
        } else {
            setIsCatalogNameAvailable(null);
            setSuggestedCatalogNames([]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isCatalogNameAvailable || catalogNameError) {
            return;
        }

        const token = localStorage.getItem('accessToken' || 'refreshToken');
        if (!token) {
            console.error('No authentication token found');
            // Handle this error, perhaps redirect to login page
            return;
        }

        try {
            await createCatalog({
                variables: {
                    name: name,
                    catalogName: catalogName
                },
                optimisticResponse: {
                    createCatalog: {
                        __typename: 'Catalog',
                        id: 'temp-id-' + Date.now(),
                        name: name,
                        catalogName: catalogName,
                        user: localStorage.getItem('userId'), // Make sure you have this
                        onboardingStatus: 'NOT_STARTED',
                        onboardingStep: 0
                    }
                }
            });
        } catch (error) {
            console.error('Error creating catalog:', error);
            // Handle specific error cases
            if (error.graphQLErrors) {
                error.graphQLErrors.forEach(({ message }) => {
                    toast.error(message);
                });
            } else if (error.networkError) {
                toast.error('Network error. Please check your connection.');
            } else {
                toast.error('An unexpected error occurred.');
            }
        }
    };

    return (
        <AuthLayout
            title="Choose Your Catalog URL"
            description="This will be the web address of your catalog"
            imageSrc="/banner1.svg"
        >
            <div className="flex justify-end mb-5">
                <button
                    onClick={handleBack}
                    className="text-[#191970] text-sm bg-gray-50 hover:underline flex items-center"
                >
                    <svg className="w-3 h-3 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    Back to Profile
                </button>
            </div>


            <form onSubmit={handleSubmit}>
                <div className="mb-6">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Store Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Mary's Bakery"
                        className="w-full px-3 py-2 mb-5 border bg-white text-black border-gray-300 rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db]"
                        required
                    />
                    <label htmlFor="catalogName" className="block text-sm font-medium text-gray-700 mb-1">Store Link</label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500 text-sm lg:text-base">
                            pricilog.com/
                        </span>
                        <input
                            type="text"
                            id="catalogName"
                            className="w-full pl-[78px] pr-10 py-2 border bg-white border-gray-300 rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db] text-sm lg:text-base lg:pl-[87px]"
                            placeholder="marys-bakery"
                            value={catalogName}
                            onChange={handleCatalogNameChange}
                        />
                        {isCatalogNameAvailable !== null && (
                            <span className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                {isCatalogNameAvailable ? (
                                    <svg className="h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                    </svg>
                                ) : (
                                    <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                    </svg>
                                )}
                            </span>
                        )}
                    </div>
                    {catalogNameError && <p className="mt-2 text-sm text-red-600">{catalogNameError}</p>}
                    {!catalogNameError && (
                        <p className="mt-2 text-xs text-gray-500">
                            Your catalog will be accessible at <span className='text-[#191970]'>www.pricilog.com/{catalogName || 'marys-bakery'} </span>
                        </p>
                    )}
                </div>

                {suggestedCatalogNames.length > 0 && (
                    <div className="mb-6">
                        <p className="text-sm font-medium text-gray-700 mb-2">Suggested catalog names:</p>
                        <ul className="space-y-1">
                            {suggestedCatalogNames.map((suggestedName, index) => (
                                <li key={index}>
                                    <button
                                        type="button"
                                        className="text-[#191970] hover:underline"
                                        onClick={() => setCatalogName(suggestedName)}
                                    >
                                        {suggestedName}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <button
                    type="submit"
                    className="w-full bg-[#191970] text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={!isCatalogNameAvailable || !!catalogNameError || createLoading}
                >
                    {createLoading ? 'Creating Catalog...' : 'Create Catalog'}
                </button>

                {createError && <p className="mt-4 text-sm text-red-600">{createError.message}</p>}
            </form>
        </AuthLayout>
    );
};

export default CatalogSetup;
import React, { useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CURRENT_USER, GET_USER_CATALOGS } from '../graphql/queries';
import { useAuth } from '../utils/authContext';
import { useNavigate } from 'react-router-dom';
import { User, Shield, CreditCard, Edit, Key, RefreshCcw, Smartphone, ToggleLeft, ToggleRight, Check, X, Plus, MoreVertical, AlertTriangle, Trash2 } from 'lucide-react';
import { useProfileOperations } from '../hooks/useProfileOperations';
import { toast } from 'react-toastify';
import LoadingSpinner from '../components/LoadingSpinner';
import { DeleteCatalogModal, DeleteAccountModal, ChangePasswordModal } from '../components/ProfilePageModal';
import Layout from '../components/Layout';

import ChangePasswordPopup from '../components/ChangePasswordPopup';
import TwoFactorAuthPopup from '../components/TwoFactorAuthPopup';

const Profile = () => {
    const navigate = useNavigate();
    const { user, updateAuthContext, logout } = useAuth();

    // States for UI management
    const [activeTab, setActiveTab] = useState('security');
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingEmail, setIsEditingEmail] = useState(false);
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
    const [isTwoFactorAuthOpen, setIsTwoFactorAuthOpen] = useState(false);
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);

    // States for catalog management
    const [catalogToDelete, setCatalogToDelete] = useState(null);
    const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false);
    const [deletionResult, setDeletionResult] = useState(null);

    // States for account deletion
    const [isConfirmingAccountDeletion, setIsConfirmingAccountDeletion] = useState(false);
    const [accountDeletionConfirmation, setAccountDeletionConfirmation] = useState('');

    // States for form data
    const [editName, setEditName] = useState('');
    const [editEmail, setEditEmail] = useState('');

    // Get optimized operations
    const {
        optimizedUpdateUser,
        optimizedDeleteCatalog,
        optimizedSelectCatalog,
        optimizedDeleteAccount,
    } = useProfileOperations(user?.id);

    // Main query for user data
    const { data: userData, loading: userLoading } = useQuery(GET_CURRENT_USER, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        onCompleted: (data) => {
            if (data?.getCurrentUser) {
                setEditName(data.getCurrentUser.name);
                setEditEmail(data.getCurrentUser.email);
                setIs2FAEnabled(data.getCurrentUser.twoFactorEnabled || false);
            }
        }
    });

    // Get user catalogs with proper variables
    const { data: catalogsData, loading: catalogsLoading } = useQuery(GET_USER_CATALOGS, {
        variables: { userId: user?.id },
        skip: !user?.id,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    // Merge loading states
    const isLoading = userLoading || catalogsLoading;

    // Get current user and catalogs from query results
    const currentUser = userData?.getCurrentUser;
    const catalogs = catalogsData?.getUserWithCatalogs?.catalogs || [];

    // Handler for catalog selection
    const handleCatalogSelect = useCallback(async (catalogId) => {
        if (!user || user.selectedCatalog?.id === catalogId) return;

        // Optimistic UI update
        updateAuthContext({ selectedCatalog: { id: catalogId } });
        localStorage.setItem('selectedCatalogId', catalogId);

        try {
            const result = await optimizedSelectCatalog(catalogId);
            if (result.data.updateSelectedCatalog) {
                toast.success('Catalog selected successfully');
                navigate('/');
            }
        } catch (error) {
            // Revert optimistic update
            updateAuthContext({ selectedCatalog: user.selectedCatalog });
            localStorage.setItem('selectedCatalogId', user.selectedCatalog?.id || '');
            toast.error('Failed to select catalog');
        }
    }, [user, optimizedSelectCatalog, updateAuthContext, navigate]);

    // Handler for saving name changes
    const handleSaveName = async () => {
        try {
            await optimizedUpdateUser({ name: editName });
            setIsEditingName(false);
            toast.success('Name updated successfully');
        } catch (error) {
            toast.error('Failed to update name');
        }
    };

    // Handler for saving email changes
    const handleSaveEmail = async () => {
        try {
            await optimizedUpdateUser({ email: editEmail });
            setIsEditingEmail(false);
            toast.success('Email updated successfully');
        } catch (error) {
            toast.error('Failed to update email');
        }
    };

    // Handler for catalog deletion
    const handleDeleteCatalog = async () => {
        if (!catalogToDelete) return;

        setIsConfirmingDeletion(true);
        try {
            await optimizedDeleteCatalog(catalogToDelete.id);

            // Select the first remaining catalog or handle empty state
            const remainingCatalogs = catalogs.filter(cat => cat.id !== catalogToDelete.id);
            if (remainingCatalogs.length > 0) {
                await handleCatalogSelect(remainingCatalogs[0].id);
            }
        } catch (error) {
            toast.error('Failed to delete catalog');
        } finally {
            setIsConfirmingDeletion(false);
        }
    };


    // Handler for account deletion
    const handleDeleteAccount = async () => {
        if (accountDeletionConfirmation.toLowerCase() !== 'delete my account') {
            toast.error('Please type the confirmation message correctly');
            return;
        }

        setIsConfirmingAccountDeletion(true);
        try {
            const result = await optimizedDeleteAccount();
            // Result handling is now managed within the hook
        } catch (error) {
            toast.error('Failed to delete account');
        } finally {
            setIsConfirmingAccountDeletion(false);
        }
    };

    // Helper functions for modal management
    const closeDeletionModal = useCallback(() => {
        setCatalogToDelete(null);
        setDeletionResult(null);
        setIsConfirmingDeletion(false);
    }, []);

    const closeAccountDeletionModal = useCallback(() => {
        setIsConfirmingAccountDeletion(false);
        setAccountDeletionConfirmation('');
    }, []);

    const redirectToNewCatalogPage = useCallback(() => {
        navigate('/catalog-setup');
    }, [navigate]);

    // Handle 2FA toggle
    const handle2FAClick = useCallback(() => {
        if (is2FAEnabled) {
            if (window.confirm('Are you sure you want to disable 2FA? This will make your account less secure.')) {
                setIs2FAEnabled(false);
            }
        } else {
            setIsTwoFactorAuthOpen(true);
        }
    }, [is2FAEnabled]);

    // Loading state
    if (isLoading) return <LoadingSpinner />;
    if (!currentUser) return null;



    // Extracted components for better organization
    const UserInfoCard = ({ user, isEditingName, isEditingEmail, editName, editEmail, setEditName, setEditEmail,
        setIsEditingName, setIsEditingEmail, handleSaveName, handleSaveEmail, catalogs, handleCatalogSelect,
        setCatalogToDelete, redirectToNewCatalogPage }) => (
        <div className="bg-white rounded-lg shadow-md p-6 h-full">
            <div className="flex items-center mb-10">
                <div className="flex-grow">
                    {/* Name editing */}
                    {isEditingName ? (
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={editName}
                                onChange={(e) => setEditName(e.target.value)}
                                className="border rounded px-2 py-1 mr-2 bg-white flex-grow"
                                autoFocus
                            />
                            <button onClick={handleSaveName} className="text-green-500 bg-gray-100 hover:text-green-700 mr-2">
                                <Check size={20} />
                            </button>
                            <button onClick={() => setIsEditingName(false)} className="text-red-500 bg-gray-100 hover:text-red-700">
                                <X size={20} />
                            </button>
                        </div>
                    ) : (
                        <h4 className="text-xl font-bold flex items-center justify-between">
                            {user.name}
                            <button onClick={() => setIsEditingName(true)} className="mr-1 text-gray-500 bg-white hover:text-gray-700">
                                <Edit size={16} />
                            </button>
                        </h4>
                    )}

                    {/* Email editing */}
                    {isEditingEmail && !user.isGoogleAccount ? (
                        <div className="flex items-center mt-1">
                            <input
                                type="email"
                                value={editEmail}
                                onChange={(e) => setEditEmail(e.target.value)}
                                className="border rounded px-2 py-1 mr-2 bg-white flex-grow"
                                autoFocus
                            />
                            <button onClick={handleSaveEmail} className="text-green-500 bg-gray-100 hover:text-green-700 mr-2">
                                <Check size={20} />
                            </button>
                            <button onClick={() => setIsEditingEmail(false)} className="text-red-500 bg-gray-100 hover:text-red-700">
                                <X size={20} />
                            </button>
                        </div>
                    ) : (
                        <p className="text-gray-600 flex items-center justify-between">
                            {user.email}
                            {!user.isGoogleAccount && (
                                <button onClick={() => setIsEditingEmail(true)} className="mr-1 text-gray-500 bg-white hover:text-gray-700">
                                    <Edit size={16} />
                                </button>
                            )}
                        </p>
                    )}
                </div>
            </div>

            {/* Catalogs List */}
            <CatalogsList
                catalogs={catalogs}
                selectedCatalogId={user.selectedCatalog?.id}
                onSelect={handleCatalogSelect}
                onDelete={setCatalogToDelete}
            />

            <button
                onClick={redirectToNewCatalogPage}
                className="flex items-center justify-center w-full bg-[#191970] text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-300"
            >
                <Plus size={16} className="mr-2" />
                Create New Catalog
            </button>
        </div>
    );

    const CatalogsList = React.memo(({ catalogs, selectedCatalogId, onSelect, onDelete }) => (
        <>
            <h4 className="text-lg font-semibold mb-2">Your Catalogs</h4>
            {catalogs && catalogs.length > 0 ? (
                <ul className="mb-4">
                    {catalogs.map((catalog) => (
                        <li
                            key={catalog.id}
                            className={`flex items-center justify-between p-2 rounded-md ${selectedCatalogId === catalog.id ? 'bg-blue-100' : 'hover:bg-gray-100'
                                }`}
                        >
                            <div
                                className="flex items-center cursor-pointer flex-grow"
                                onClick={() => onSelect(catalog.id)}
                            >
                                <img
                                    src={catalog.logo || '/../logo-placeholder.svg'}
                                    alt={`${catalog.name} logo`}
                                    className="w-8 h-8 mr-2 rounded-full"
                                />
                                <span>{catalog.catalogName}</span>
                            </div>
                            <button
                                onClick={() => onDelete(catalog)}
                                className="text-gray-500 bg-gray-100 hover:text-gray-700"
                            >
                                <Trash2 className="text-red-600" size={16} />
                            </button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="mb-4">No catalogs found.</p>
            )}
        </>
    ));

    const SecuritySettings = React.memo(({ setIsChangePasswordOpen, handleDeleteAccount }) => (
        <div className="bg-white rounded-lg shadow-md p-6">
            <h4 className="text-xl font-semibold mb-4">Security Settings</h4>
            <div className="space-y-6">
                <div>
                    <h4 className="font-medium mb-2">Change Password</h4>
                    <button
                        className="bg-[#191970] text-white px-4 py-2 rounded-md hover:bg-opacity-90"
                        onClick={() => setIsChangePasswordOpen(true)}
                    >
                        <Key className="inline-block mr-2" size={16} />
                        Change Password
                    </button>
                </div>
            </div>
        </div>
    ));


    return (
        <Layout title="My Profile" description="See your personal details">
            <div className="p-1 sm:p-1 lg:p-8 bg-gray-100 mb-20">
                <div className="flex flex-col lg:flex-row gap-8">
                    {/* Left Column */}
                    <div className="w-full lg:w-1/3">
                        <UserInfoCard
                            user={currentUser}
                            isEditingName={isEditingName}
                            isEditingEmail={isEditingEmail}
                            editName={editName}
                            editEmail={editEmail}
                            setEditName={setEditName}
                            setEditEmail={setEditEmail}
                            setIsEditingName={setIsEditingName}
                            setIsEditingEmail={setIsEditingEmail}
                            handleSaveName={handleSaveName}
                            handleSaveEmail={handleSaveEmail}
                            catalogs={catalogs}
                            handleCatalogSelect={handleCatalogSelect}
                            setCatalogToDelete={setCatalogToDelete}
                            redirectToNewCatalogPage={redirectToNewCatalogPage}
                        />
                    </div>

                    {/* Right Column */}
                    <div className="w-full lg:w-2/3">
                        {/* Tabs */}
                        <div className="bg-gray-100 rounded-lg p-2 mb-4">
                            <button
                                className={`px-4 py-2 rounded-md mr-2 ${activeTab === 'security' ? 'bg-white shadow-md' : 'hover:bg-gray-200'
                                    }`}
                                onClick={() => setActiveTab('security')}
                            >
                                <Shield className="inline-block mr-2" size={16} />
                                Security
                            </button>
                        </div>

                        {/* Security Settings */}
                        <SecuritySettings
                            setIsChangePasswordOpen={setIsChangePasswordOpen}
                            handleDeleteAccount={handleDeleteAccount}
                        />

                        {/* Delete Account Section */}
                        <div className="mt-10 bg-red-100 p-3 rounded-xl">
                            <h4 className="font-medium mb-2">Delete Account</h4>
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                                onClick={() => setIsConfirmingAccountDeletion(true)}
                            >
                                <AlertTriangle className="inline-block mr-2" size={16} />
                                Delete Account
                            </button>
                            <p className="text-sm text-gray-600 mt-2">
                                Warning: This action is irreversible and will permanently delete your account and all associated data.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Modals */}
                <ChangePasswordPopup isOpen={isChangePasswordOpen} onClose={() => setIsChangePasswordOpen(false)} />
                <TwoFactorAuthPopup isOpen={isTwoFactorAuthOpen} onClose={() => setIsTwoFactorAuthOpen(false)} />
                <DeleteCatalogModal
                    isOpen={Boolean(catalogToDelete)}
                    catalog={catalogToDelete}
                    isConfirming={isConfirmingDeletion}
                    deletionResult={deletionResult}
                    onConfirm={handleDeleteCatalog}
                    onClose={closeDeletionModal}
                />
                <DeleteAccountModal
                    isOpen={isConfirmingAccountDeletion}
                    confirmation={accountDeletionConfirmation}
                    setConfirmation={setAccountDeletionConfirmation}
                    onConfirm={handleDeleteAccount}
                    onClose={closeAccountDeletionModal}
                    deletionResult={deletionResult}
                />
            </div>
        </Layout>
    )
};

export default Profile;
// hooks/useCatalogOperations.js
import { useMutation, useApolloClient } from '@apollo/client';
import { CREATE_ITEM, CREATE_CATEGORY, UPDATE_ITEM, DELETE_ITEM, UPDATE_CATEGORY, DELETE_CATEGORY, REORDER_CATALOG } from '../graphql/mutations';
import { GET_CATALOG_ITEMS_AND_CATEGORIES } from '../graphql/queries';

const DEFAULT_ITEM = {
    name: '',
    description: '',
    priceVariants: [],
    image: null,
    category: null,
    isVisible: true,
    order: undefined,
    clickCount: undefined,
};


const DEFAULT_CATEGORY = {
    name: '',
    isVisible: true,
    items: [],
};

export const useCatalogOperations = (catalogId) => {
    const [createItem] = useMutation(CREATE_ITEM);
    const [createCategory] = useMutation(CREATE_CATEGORY);
    const [updateItem] = useMutation(UPDATE_ITEM);
    const [deleteItem] = useMutation(DELETE_ITEM);
    const [updateCategory] = useMutation(UPDATE_CATEGORY);
    const [deleteCategory] = useMutation(DELETE_CATEGORY);
    const [reorderCatalog] = useMutation(REORDER_CATALOG);
    const client = useApolloClient();

    const generateItemOptimisticResponse = (currentData = {}, newData = {}) => {
        const data = {
            ...DEFAULT_ITEM,
            ...currentData,
            ...newData,
        };

        return {
            __typename: 'Mutation',
            createItem: {
                __typename: 'Item',
                id: 'temp-id-' + Date.now(),
                ...data,
            },
        };
    };

    const generateCategoryOptimisticResponse = (currentData = {}, newData = {}) => {
        const data = {
            ...DEFAULT_CATEGORY,
            ...currentData,
            ...newData,
        };

        return {
            __typename: 'Mutation',
            createCategory: {
                __typename: 'Category',
                id: 'temp-id-' + Date.now(),
                ...data,
            },
        };
    };


    const optimizedCreateItem = async (variables) => {
        // Clean up price variants
        const cleanedVariables = {
            ...variables,
            priceVariants: variables.priceVariants?.map(variant => ({
                variant: variant.variant,
                price: variant.price
            }))
        };

        return createItem({
            variables: cleanedVariables,
            optimisticResponse: {
                __typename: 'Mutation',
                createItem: {
                    __typename: 'Item',
                    id: `temp-id-${Date.now()}`,
                    ...cleanedVariables,
                    isVisible: true,
                }
            },
            update: (cache, { data }) => {
                const newItem = data?.createItem;
                if (!newItem) return;

                try {
                    const existingData = cache.readQuery({
                        query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                        variables: { catalogId }
                    });

                    if (!existingData) return;

                    const updatedData = {
                        catalogItemsAndCategories: {
                            ...existingData.catalogItemsAndCategories,
                            categories: existingData.catalogItemsAndCategories.categories.map(category => {
                                if (category.id === newItem.category) {
                                    return {
                                        ...category,
                                        items: [...category.items, newItem]
                                    };
                                }
                                return category;
                            }),
                            uncategorizedItems: !newItem.category
                                ? [...existingData.catalogItemsAndCategories.uncategorizedItems, newItem]
                                : existingData.catalogItemsAndCategories.uncategorizedItems
                        }
                    };

                    cache.writeQuery({
                        query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                        variables: { catalogId },
                        data: updatedData
                    });
                } catch (error) {
                    console.error('Cache update error:', error);
                    throw new Error('Failed to update cache after item creation');
                }
            }
        });
    };

    const optimizedCreateCategory = async (variables) => {
        return createCategory({
            variables,
            optimisticResponse: generateCategoryOptimisticResponse({}, variables),
            update: (cache, { data }) => {
                const newCategory = data?.createCategory;
                if (!newCategory) return;

                const existingData = cache.readQuery({
                    query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                    variables: { catalogId },
                });

                if (!existingData) return;

                const updatedData = {
                    catalogItemsAndCategories: {
                        ...existingData.catalogItemsAndCategories,
                        categories: [...existingData.catalogItemsAndCategories.categories, newCategory],
                    },
                };

                cache.writeQuery({
                    query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                    variables: { catalogId },
                    data: updatedData,
                });
            },
        });
    };

    const optimizedDeleteItem = async (itemId) => {
        if (!itemId) {
            throw new Error('Item ID is required');
        }

        return deleteItem({
            variables: { id: itemId },
            optimisticResponse: {
                __typename: 'Mutation',
                deleteItem: 'Item removed'
            },
            update: (cache) => {
                const existingData = cache.readQuery({
                    query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                    variables: { catalogId },
                });

                if (!existingData) return;

                const updatedData = {
                    catalogItemsAndCategories: {
                        ...existingData.catalogItemsAndCategories,
                        categories: existingData.catalogItemsAndCategories.categories.map(category => ({
                            ...category,
                            items: category.items.filter(item => item.id !== itemId),
                        })),
                        uncategorizedItems: existingData.catalogItemsAndCategories.uncategorizedItems
                            .filter(item => item.id !== itemId),
                    },
                };

                cache.writeQuery({
                    query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                    variables: { catalogId },
                    data: updatedData,
                });
            },
        });
    };

    const optimizedUpdateItem = async (variables) => {
        const { id, image, ...updates } = variables;

        // Get existing item data from cache
        const existingData = client.readQuery({
            query: GET_CATALOG_ITEMS_AND_CATEGORIES,
            variables: { catalogId }
        });

        // Find current item data
        const currentItem = existingData?.catalogItemsAndCategories?.categories
            .reduce((found, category) => {
                if (found) return found;
                return category.items.find(item => item.id === id);
            }, null) ||
            existingData?.catalogItemsAndCategories?.uncategorizedItems
                .find(item => item.id === id);

        if (!currentItem) {
            console.error('Item not found in cache');
            return;
        }

        // Only include fields that have actually changed
        const changedFields = {};
        Object.keys(updates).forEach(key => {
            if (JSON.stringify(updates[key]) !== JSON.stringify(currentItem[key])) {
                changedFields[key] = updates[key];
            }
        });

        return updateItem({
            variables: { id, image, ...changedFields },
            optimisticResponse: {
                __typename: 'Mutation',
                updateItem: {
                    ...currentItem,
                    ...changedFields,
                    __typename: 'Item',
                    id,
                }
            },
            update: (cache, { data }) => {
                const updatedItem = data?.updateItem;
                if (!updatedItem) return;

                const existingData = cache.readQuery({
                    query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                    variables: { catalogId }
                });

                if (!existingData) return;

                // Update items in all categories
                const updatedCategories = existingData.catalogItemsAndCategories.categories.map(category => ({
                    ...category,
                    items: category.items.map(item =>
                        item.id === id ? updatedItem : item
                    )
                }));

                // Update uncategorized items
                const updatedUncategorizedItems = existingData.catalogItemsAndCategories.uncategorizedItems
                    .map(item => item.id === id ? updatedItem : item);

                cache.writeQuery({
                    query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                    variables: { catalogId },
                    data: {
                        catalogItemsAndCategories: {
                            ...existingData.catalogItemsAndCategories,
                            categories: updatedCategories,
                            uncategorizedItems: updatedUncategorizedItems
                        }
                    }
                });
            }
        });
    };

    const optimizedUpdateCategory = async (variables) => {
        const { id, ...updates } = variables;

        return updateCategory({
            variables,
            optimisticResponse: {
                __typename: 'Mutation',
                updateCategory: {
                    __typename: 'Category',
                    id,
                    ...updates,
                },
            },
            update: (cache, { data }) => {
                const updatedCategory = data?.updateCategory;
                if (!updatedCategory) return;

                // Update category fields in cache
                cache.modify({
                    id: cache.identify({ __typename: 'Category', id }),
                    fields: Object.keys(updates).reduce((acc, key) => ({
                        ...acc,
                        [key]: () => updates[key],
                    }), {}),
                });


                // Update the category in the GET_CATALOG_ITEMS_AND_CATEGORIES query
                try {
                    const existingData = cache.readQuery({
                        query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                        variables: { catalogId },
                    });

                    if (!existingData) return;

                    const updatedData = {
                        catalogItemsAndCategories: {
                            ...existingData.catalogItemsAndCategories,
                            categories: existingData.catalogItemsAndCategories.categories.map(category =>
                                category.id === id ? { ...category, ...updates } : category
                            ),
                        },
                    };

                    cache.writeQuery({
                        query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                        variables: { catalogId },
                        data: updatedData,
                    });
                } catch (error) {
                    console.error('Cache update error:', error);
                }

            },
        });
    };

    const optimizedDeleteCategory = async (categoryId) => {
        return deleteCategory({
            variables: { id: categoryId },
            optimisticResponse: {
                __typename: 'Mutation',
                deleteCategory: 'Category removed'
            },
            update: (cache, { data }) => {
                try {
                    // Read the existing data
                    const existingData = cache.readQuery({
                        query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                        variables: { catalogId }
                    });

                    if (!existingData) return;

                    // Find the category being deleted to get its items
                    const categoryToDelete = existingData.catalogItemsAndCategories.categories
                        .find(cat => cat.id === categoryId);
                    const itemsToDelete = categoryToDelete?.items || [];

                    // Remove all items in the category from cache
                    itemsToDelete.forEach(item => {
                        cache.evict({
                            id: cache.identify({ __typename: 'Item', id: item.id })
                        });
                    });

                    // Update the main catalog data
                    const updatedData = {
                        catalogItemsAndCategories: {
                            ...existingData.catalogItemsAndCategories,
                            categories: existingData.catalogItemsAndCategories.categories
                                .filter(category => category.id !== categoryId)
                        }
                    };

                    // Write the updated data back to cache
                    cache.writeQuery({
                        query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                        variables: { catalogId },
                        data: updatedData
                    });

                    // Remove the category from the cache
                    cache.evict({ id: cache.identify({ __typename: 'Category', id: categoryId }) });
                    cache.gc();
                } catch (error) {
                    console.error('Cache update error:', error);
                    throw error;
                }
            }
        });
    };

    const optimizedToggleItemVisibility = async (itemId, currentVisibility) => {
        if (typeof itemId !== 'string' || typeof currentVisibility !== 'boolean') {
            throw new Error('Invalid parameters: itemId must be a string and currentVisibility must be a boolean');
        }

        return updateItem({
            variables: {
                id: itemId,
                isVisible: !currentVisibility
            },
            optimisticResponse: {
                __typename: 'Mutation',
                updateItem: {
                    __typename: 'Item',
                    id: itemId,
                    isVisible: !currentVisibility
                }
            },
            update: (cache, { data }) => {
                const updatedItem = data?.updateItem;
                if (!updatedItem) return;

                // Update the item's visibility in the cache
                cache.modify({
                    id: cache.identify({ __typename: 'Item', id: itemId }),
                    fields: {
                        isVisible: () => !currentVisibility
                    }
                });

                // Update the item in the main catalog query
                try {
                    const existingData = cache.readQuery({
                        query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                        variables: { catalogId }
                    });

                    if (!existingData) return;

                    const updateItemInList = (items) =>
                        items.map(item =>
                            item.id === itemId
                                ? { ...item, isVisible: !currentVisibility }
                                : item
                        );

                    const updatedData = {
                        catalogItemsAndCategories: {
                            ...existingData.catalogItemsAndCategories,
                            // Update items in categories
                            categories: existingData.catalogItemsAndCategories.categories.map(category => ({
                                ...category,
                                items: category.items ? updateItemInList(category.items) : []
                            })),
                            // Update uncategorized items
                            uncategorizedItems: updateItemInList(
                                existingData.catalogItemsAndCategories.uncategorizedItems
                            )
                        }
                    };

                    cache.writeQuery({
                        query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                        variables: { catalogId },
                        data: updatedData
                    });
                } catch (error) {
                    console.error('Cache update error:', error);
                    // Even if the cache update fails, the server update might have succeeded
                    // Consider refetching the data in this case
                    return;
                }
            }
        });
    };

    const optimizedToggleCategoryVisibility = async (categoryId, currentVisibility) => {
        if (typeof categoryId !== 'string' || typeof currentVisibility !== 'boolean') {
            throw new Error('Invalid parameters: categoryId must be a string and currentVisibility must be a boolean');
        }

        return updateCategory({
            variables: {
                id: categoryId,
                isVisible: !currentVisibility
            },
            optimisticResponse: {
                __typename: 'Mutation',
                updateCategory: {
                    __typename: 'Category',
                    id: categoryId,
                    isVisible: !currentVisibility
                }
            },
            update: (cache, { data }) => {
                const updatedCategory = data?.updateCategory;
                if (!updatedCategory) return;

                // Update the individual category's visibility in the cache
                cache.modify({
                    id: cache.identify({ __typename: 'Category', id: categoryId }),
                    fields: {
                        isVisible: () => !currentVisibility
                    }
                });

                // Update the category in the main catalog query
                try {
                    const existingData = cache.readQuery({
                        query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                        variables: { catalogId }
                    });

                    if (!existingData) return;

                    const updatedData = {
                        catalogItemsAndCategories: {
                            ...existingData.catalogItemsAndCategories,
                            categories: existingData.catalogItemsAndCategories.categories.map(category =>
                                category.id === categoryId
                                    ? { ...category, isVisible: !currentVisibility }
                                    : category
                            )
                        }
                    };

                    cache.writeQuery({
                        query: GET_CATALOG_ITEMS_AND_CATEGORIES,
                        variables: { catalogId },
                        data: updatedData
                    });
                } catch (error) {
                    console.error('Cache update error:', error);
                    // Consider refetching if cache update fails
                    throw new Error('Failed to update category visibility in cache');
                }
            }
        });
    };

    return {
        optimizedCreateItem,
        optimizedCreateCategory,
        optimizedDeleteItem,
        optimizedUpdateItem,
        optimizedUpdateCategory,
        optimizedDeleteCategory,
        optimizedToggleItemVisibility,
        optimizedToggleCategoryVisibility
    };
};
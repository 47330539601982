import { useEffect, useState, useCallback } from 'react';
import { driver } from 'driver.js';
import { useMutation } from '@apollo/client';
import { UPDATE_TOUR_STATUS } from '../graphql/mutations';
import { tourSteps } from '../utils/tourConfig';
import { useAuth } from '../utils/authContext';
import 'driver.js/dist/driver.css';

export const useTour = (pageKey) => {
    const [driverObj, setDriverObj] = useState(null);
    const [updateTourStatus] = useMutation(UPDATE_TOUR_STATUS);
    const { user, refetchUserData } = useAuth();

    useEffect(() => {
        if (!driverObj && !user?.tourStatus?.[pageKey]) {
            const steps = tourSteps(pageKey);

            if (steps?.length > 0) {
                const driverInstance = driver({
                    animate: true,
                    showProgress: true,
                    showButtons: true,
                    overlayClickNext: true,
                    steps,
                    onDestroyed: async (element, step, options) => {
                        try {
                            await updateTourStatus({
                                variables: {
                                    input: {
                                        page: pageKey,
                                        completed: true
                                    }
                                }
                            });
                            await refetchUserData();
                        } catch (error) {
                            console.error('Error updating tour status:', error);
                        }
                    }
                });
                setDriverObj(driverInstance);
            }
        }

        return () => {
            if (driverObj) {
                driverObj.destroy();
            }
        };
    }, [pageKey, driverObj, user?.tourStatus, updateTourStatus, refetchUserData]);

    const startTour = useCallback(() => {
        if (driverObj && !user?.tourStatus?.[pageKey]) {
            driverObj.drive();
        }
    }, [driverObj, pageKey, user?.tourStatus]);

    return { startTour };
};
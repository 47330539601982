const isMobile = () => window.innerWidth < 1024;


// Define tour steps for each page
export const tourSteps = (pageKey) => {
    const baseSteps = {
        overview: [
            {
                element: '.dynamic-el',
                popover: {
                    title: 'Welcome to Pricilog! 👋',
                    description: "Let's take a quick tour of your dashboard to help you get started."
                }
            },

            // Desktop Navigation
            {
                element: '.navigation-menu',
                popover: {
                    title: 'Navigation Menu',
                    description: 'Find all your key features here: catalog management, customization, and analytics.',
                    position: 'right'
                },
                onlyOn: 'desktop'
            },
            {
                element: '[href="/overview"]',
                popover: {
                    title: 'Overview',
                    description: 'Your dashboard home - track performance, share your catalog, and see quick stats.',
                    position: 'right'
                },
                onlyOn: 'desktop'
            },
            {
                element: '[href="/catalog"]',
                popover: {
                    title: 'Catalog',
                    description: 'Add products, create categories, and manage your items here.',
                    position: 'right'
                },
                onlyOn: 'desktop'
            },
            {
                element: '[href="/personalization"]',
                popover: {
                    title: 'Personalization',
                    description: 'Customize your catalog\'s look, add your logo, and set business details.',
                    position: 'right'
                },
                onlyOn: 'desktop'
            },
            {
                element: '[href="/statistics"]',
                popover: {
                    title: 'Statistics',
                    description: 'See detailed analytics about visits, clicks, and popular items.',
                    position: 'right'
                },
                onlyOn: 'desktop'
            },

            // Mobile Navigation
            {
                element: '.mobile-nav',
                popover: {
                    title: 'Quick Access Menu',
                    description: 'Access all features from this bottom menu',
                    position: 'top'
                },
                onlyOn: 'mobile'
            },
            {
                element: '.mobile-nav [href="/overview"]', // Changed selector order
                popover: {
                    title: 'Overview',
                    description: 'Your dashboard home and key metrics',
                    position: 'top'
                },
                onlyOn: 'mobile'
            },
            {
                element: '.mobile-nav [href="/catalog"]', // Changed selector order
                popover: {
                    title: 'Catalog',
                    description: 'Manage your products and categories',
                    position: 'top'
                },
                onlyOn: 'mobile'
            },
            {
                element: '.mobile-nav [href="/personalization"]', // Changed selector order
                popover: {
                    title: 'Personalization',
                    description: 'Customize your catalog\'s appearance',
                    position: 'top'
                },
                onlyOn: 'mobile'
            },
            {
                element: '.mobile-nav [href="/statistics"]', // Changed selector order
                popover: {
                    title: 'Statistics',
                    description: 'View performance analytics',
                    position: 'top'
                },
                onlyOn: 'mobile'
            },


            //common for both
            {
                element: isMobile() ? '.sharing-section-mobile' : '.sharing-section-desktop',
                popover: {
                    title: 'Share Your Catalog',
                    description: 'Get your unique catalog link and QR code to share with customers',
                    position: isMobile() ? 'bottom' : 'left'
                }
            },
            {
                element: '.performance-metrics',
                popover: {
                    title: 'Track Performance',
                    description: 'Monitor views, clicks and customer engagement',
                    position: isMobile() ? 'bottom' : 'left'
                }
            }
        ],
        // overview: [
        //     {
        //         element: '.navigation-menu',  // Add this class to your nav element
        //         popover: {
        //             title: 'Navigation Menu',
        //             description: 'Here\'s a quick guide to help you navigate:',
        //             position: 'right'
        //         }
        //     },
        //     {
        //         element: '[href="/overview"]',
        //         popover: {
        //             title: 'Overview',
        //             description: 'Track your catalog performance and share with customers',
        //             position: 'right'
        //         }
        //     },
        //     {
        //         element: '[href="/catalog"]',
        //         popover: {
        //             title: 'Catalog',
        //             description: 'Add and manage your products and categories',
        //             position: 'right'
        //         }
        //     },
        //     {
        //         element: '[href="/personalization"]',
        //         popover: {
        //             title: 'Personalization',
        //             description: 'Customize your catalog\'s appearance and branding',
        //             position: 'right'
        //         }
        //     },
        //     {
        //         element: '[href="/statistics"]',
        //         popover: {
        //             title: 'Statistics',
        //             description: 'View detailed analytics and customer engagement',
        //             position: 'right'
        //         }
        //     },
        //     {
        //         element: '.sharing-section',
        //         popover: {
        //             title: 'Share Your Catalog',
        //             description: 'Get your unique link and QR code to share with customers',
        //             position: 'bottom'
        //         }
        //     },
        //     {
        //         element: '.performance-metrics',
        //         popover: {
        //             title: 'Track Performance',
        //             description: 'Monitor views, clicks and customer engagement',
        //             position: 'left'
        //         }
        //     }
        // ],
        catalog: [
            {
                element: '.catalog-actions',
                popover: {
                    title: 'Add Items & Categories',
                    description: 'Start building your catalog by adding items or creating categories',
                    position: 'bottom'
                }
            },
            {
                element: '.items-section',
                popover: {
                    title: 'Manage Your Items',
                    description: 'View, edit, and organize your items here',
                    position: 'top'
                }
            }
        ],

        personalization: [
            {
                element: '.brand-info',
                popover: {
                    title: 'Business Information',
                    description: 'Add your logo and business details',
                    position: 'right'
                }
            },
            {
                element: '.theme-section',
                popover: {
                    title: 'Choose Your Theme',
                    description: 'Select a theme that matches your brand',
                    position: 'left'
                }
            }
        ],

        statistics: [
            {
                element: '.performance-stats',
                popover: {
                    title: 'Performance Overview',
                    description: 'Track your catalog metrics and engagement',
                    position: 'bottom'
                }
            },
            {
                element: '.analytics-chart',
                popover: {
                    title: 'Visitor Analytics',
                    description: 'Monitor daily visits and trends',
                    position: 'top'
                }
            }
        ],
    };

    // Filter steps based on device
    return baseSteps[pageKey].filter(step => {
        if (step.onlyOn === 'desktop' && isMobile()) return false;
        if (step.onlyOn === 'mobile' && !isMobile()) return false;
        return true;
    });

};
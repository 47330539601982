import React from 'react';

export const BusinessSetupStepper = ({ currentStep }) => {
    const steps = [
        { id: 1, title: 'Business Type' },
        { id: 2, title: 'Business Category' },
        { id: 3, title: 'Contact Info' },
        { id: 4, title: 'Review' }
    ];

    return (
        <div className="w-full py-4">
            <div className="flex items-center justify-between mb-8">
                {steps.map((step, idx) => (
                    <React.Fragment key={step.id}>
                        {/* Step Circle */}
                        <div className="flex flex-col items-center">
                            <div
                                className={`w-8 h-8 rounded-full flex items-center justify-center ${currentStep >= step.id
                                    ? 'bg-[#191970] text-white'
                                    : 'bg-gray-200 text-gray-500'
                                    }`}
                            >
                                {step.id}
                            </div>
                            <span className={`mt-2 text-xs ${currentStep >= step.id ? 'text-[#191970]' : 'text-gray-500'
                                }`}>
                                {step.title}
                            </span>
                        </div>

                        {/* Connector Line */}
                        {idx < steps.length - 1 && (
                            <div className="flex-1 h-0.5 mx-4">
                                <div className={`h-full ${currentStep > step.id ? 'bg-[#191970]' : 'bg-gray-200'
                                    }`} />
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};


import React, { memo } from 'react';
import { ChevronDown, ChevronUp, MessageCircle } from 'lucide-react';
import { useQuery } from '@apollo/client';
import Layout from '../components/Layout';
import ContactFormPopup from '../components/ContactFormPopup';
import { GET_VENDOR_FAQS } from '../graphql/queries';
import LoadingSpinner from '../components/LoadingSpinner';

// Memoized FAQ Item to prevent unnecessary re-renders
const FAQItem = memo(({ question, answer, isOpen, onToggle }) => (
    <div className="mb-5 bg-white rounded-lg shadow-md overflow-hidden">
        <button
            className="w-full p-4 bg-white text-left flex justify-between items-center focus:outline-none"
            onClick={onToggle}
        >
            <span className="font-semibold">{question}</span>
            {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
        {isOpen && (
            <div className="p-4 bg-gray-50">
                <div className="prose max-w-none">{answer}</div>
            </div>
        )}
    </div>
));

// Memoized FAQ List to prevent re-renders when contact form state changes
const FAQList = memo(({ faqs, openIndex, onToggle }) => (
    <div className="rounded-lg mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#191970]">
            Frequently Asked Questions
        </h2>
        {faqs.map((faq, index) => (
            <FAQItem
                key={faq.id}
                question={faq.question}
                answer={faq.answer}
                isOpen={openIndex === index}
                onToggle={() => onToggle(index)}
            />
        ))}
    </div>
));

// Memoized Contact Section to prevent re-renders
const ContactSection = memo(({ onContactClick }) => (
    <div className="bg-sky-100 rounded-lg shadow-lg p-6 text-center">
        <h2 className="text-2xl font-semibold mb-2 text-[#191970]">
            Still Have Questions?
        </h2>
        <p className="mb-4 text-gray-700">
            Can't find the answer you're looking for? Chat with our friendly team!
        </p>
        <button
            onClick={onContactClick}
            className="inline-flex items-center px-6 py-3 bg-[#191970] text-white font-medium rounded-lg hover:bg-opacity-90 transition duration-300"
        >
            <MessageCircle size={20} className="mr-2" />
            Get in Touch
        </button>
    </div>
));

const HelpSupport = () => {
    // Use reducer instead of multiple useState for better performance
    const [state, setState] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            openIndex: -1,
            isContactFormOpen: false
        }
    );

    // Optimize query with proper fetchPolicy
    const { loading, error, data } = useQuery(GET_VENDOR_FAQS, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-only',
    });

    // Memoized handlers to prevent recreation on every render
    const handleFAQToggle = React.useCallback((index) => {
        setState({ openIndex: state.openIndex === index ? -1 : index });
    }, [state.openIndex]);

    const handleContactClick = React.useCallback(() => {
        setState({ isContactFormOpen: true });
    }, []);

    const handleContactClose = React.useCallback(() => {
        setState({ isContactFormOpen: false });
    }, []);

    if (loading) return <LoadingSpinner />;
    if (error) return error.message;

    return (
        <Layout
            title="Help & Support"
            description="Get the help you need here"
        >
            <div className="container mx-auto p-1 sm:p-1 lg:p-8 max-w-4xl mb-20">
                <FAQList
                    faqs={data.vendorFAQs}
                    openIndex={state.openIndex}
                    onToggle={handleFAQToggle}
                />
                <ContactSection onContactClick={handleContactClick} />
            </div>

            <ContactFormPopup
                isOpen={state.isContactFormOpen}
                onClose={handleContactClose}
            />
        </Layout>
    );
};

export default React.memo(HelpSupport);
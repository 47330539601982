import React, { useCallback } from 'react';
import { businessCategories } from '../data/businessInfo';


export const BusinessSetupBanner = ({ onStartSetup }) => (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 mb-6 w-[98%]">
        <div className="flex items-center justify-between">
            <div>
                <h3 className="text-lg font-medium text-gray-900">Complete Your Business Profile</h3>
                <p className="text-sm text-gray-500 mt-1">
                    Help customers find your business by setting up your profile details
                </p>
            </div>
            <button
                onClick={onStartSetup}
                className="px-4 py-2 bg-[#191970] text-white rounded-md hover:bg-opacity-90"
            >
                Start Setup
            </button>
        </div>
    </div>
);

export const BusinessInfoCard = ({ catalog }) => {
    // Get subcategory display names from the data file
    const getSubCategoryDisplayNames = useCallback(() => {
        if (!catalog.businessCategory || !catalog.subCategories) return [];
        const categorySubcategories = businessCategories[catalog.businessCategory] || [];
        return catalog.subCategories.filter(sub => categorySubcategories.includes(sub));
    }, [catalog.businessCategory, catalog.subCategories]);

    return (
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 mb-6">
            <div className="flex justify-between items-start">
                <h3 className="text-lg font-medium text-gray-900">Business Information</h3>
                <button className="text-sm text-[#191970] hover:underline">
                    Edit Details
                </button>
            </div>

            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <h4 className="text-sm font-medium text-gray-500">Basic Info</h4>
                    <div className="mt-2 space-y-2">
                        <p className="text-sm">
                            <span className="font-medium">Name:</span> {catalog.name}
                        </p>
                        {catalog.description && (
                            <p className="text-sm">
                                <span className="font-medium">Description:</span> {catalog.description}
                            </p>
                        )}
                        <p className="text-sm">
                            <span className="font-medium">Type:</span> {catalog.businessType.toLowerCase()}
                        </p>
                        {catalog.location && (
                            <p className="text-sm">
                                <span className="font-medium">Location:</span> {catalog.location}
                            </p>
                        )}
                    </div>
                </div>

                <div>
                    <h4 className="text-sm font-medium text-gray-500">Category Details</h4>
                    <div className="mt-2 space-y-2">
                        <p className="text-sm">
                            <span className="font-medium">Main Category:</span> {catalog.businessCategory}
                        </p>
                        {getSubCategoryDisplayNames().length > 0 && (
                            <div className="text-sm">
                                <span className="font-medium">Specializations:</span>
                                <div className="mt-1 flex flex-wrap gap-2">
                                    {getSubCategoryDisplayNames().map(sub => (
                                        <span
                                            key={sub}
                                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                                        >
                                            {sub}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="md:col-span-2">
                    <h4 className="text-sm font-medium text-gray-500">Contact & Checkout</h4>
                    <div className="mt-2 space-y-2">
                        {catalog.phone && (
                            <p className="text-sm">
                                <span className="font-medium">WhatsApp:</span> {catalog.phone}
                            </p>
                        )}
                        <p className="text-sm">
                            <span className="font-medium">WhatsApp Checkout:</span>
                            {catalog.allowCheckout ? (
                                <span className="text-green-600 ml-1">Enabled</span>
                            ) : (
                                <span className="text-gray-500 ml-1">Disabled</span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

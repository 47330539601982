import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CATALOG_ITEMS_AND_CATEGORIES } from '../graphql/queries';
import { CREATE_ITEM, CREATE_CATEGORY, UPDATE_ITEM, DELETE_ITEM, UPDATE_CATEGORY, DELETE_CATEGORY, REORDER_CATALOG } from '../graphql/mutations';
import { useAuth } from '../utils/authContext';
import Layout from '../components/Layout';
import { Search, Plus, Filter, ChevronDown, ChevronUp, ChevronLeft, ChevronRight, Edit, Trash } from 'lucide-react';
import AddItemPopup from '../components/AddItemPopup';
import AddCategoryPopup from '../components/AddCategoryPopup';
import SuccessMessagePopup from '../components/SuccessMessagePopup';
import ItemGrid from '../components/ItemGrid';
import ItemDetailPopup from '../components/ItemDetailPopup';
import EditCategoryPopup from '../components/EditCategoryPopup';
import DeleteConfirmationPopup from '../components/DeleteConfirmationPopup';
import Toggle from '../components/Toggle';
import ReturnToOnboardingButton from '../components/ReturnToOnboardingButton';
import LoadingSpinner from '../components/LoadingSpinner';
import CatalogManagement from '../components/CatalogManagement';
import { useCallback } from 'react';
import ErrorBoundary from '../components/ErrorBoundary';
import EditItemPopup from '../components/EditItemPopup';
import { useTour } from '../hooks/useTour';
import { useCatalogOperations } from '../hooks/useCatalogOperations';


const Catalog = () => {
    const { user, catalogs, selectedCatalog, refetchUserData } = useAuth();
    const catalogId = user?.selectedCatalog?.id || catalogs[0]?.id;

    const {
        optimizedCreateItem, optimizedCreateCategory, optimizedDeleteItem, optimizedUpdateItem,
        optimizedUpdateCategory,
        optimizedDeleteCategory,
        optimizedToggleItemVisibility,
        optimizedToggleCategoryVisibility, } = useCatalogOperations(catalogId);

    const { loading, error, data, refetch } = useQuery(GET_CATALOG_ITEMS_AND_CATEGORIES, {
        variables: { catalogId },
        skip: !catalogId,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });


    const [reorderCatalog] = useMutation(REORDER_CATALOG);
    const [isManagementMode, setIsManagementMode] = useState(false);

    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [filter, setFilter] = useState('all');
    const [isAddItemPopupOpen, setIsAddItemPopupOpen] = useState(false);
    const [isAddCategoryPopupOpen, setIsAddCategoryPopupOpen] = useState(false);
    const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);
    const [successMessageContent, setSuccessMessageContent] = useState({ title: '', message: '' });
    const [quickAddCategory, setQuickAddCategory] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isItemDetailPopupOpen, setIsItemDetailPopupOpen] = useState(false);


    const [deleteCategory] = useMutation(DELETE_CATEGORY);
    const [isEditCategoryPopupOpen, setIsEditCategoryPopupOpen] = useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

    const [itemToDelete, setItemToDelete] = useState(null);
    const [isDeleteItemConfirmationOpen, setIsDeleteItemConfirmationOpen] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState(null);

    const [isEditItemPopupOpen, setIsEditItemPopupOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const { startTour } = useTour('catalog');


    //product tour
    const isNewUser = !user?.tourStatus?.catalog;
    useEffect(() => {
        if (selectedCatalog?.setupCompleted && isNewUser) {
            const tourKey = `${selectedCatalog.id}_catalog`;
            if (!localStorage.getItem(`${tourKey}TourComplete`)) {
                const timer = setTimeout(() => {
                    startTour();
                    console.log("Catalog TOUR STARTED");
                }, 500);
                return () => clearTimeout(timer);
            }
        }
    }, [selectedCatalog, startTour]);


    useEffect(() => {
        refetchUserData(); // Refetch user data when component mounts
    }, [refetchUserData]);

    useEffect(() => {
        if (data && data.catalogItemsAndCategories) {
            const categoriesWithItemCount = data.catalogItemsAndCategories.categories.map(cat => ({
                ...cat,
                isExpanded: true,
                items: cat.items || [],
                itemCount: cat.items ? cat.items.length : 0
            }));

            setCategories(categoriesWithItemCount);

            setItems([
                ...data.catalogItemsAndCategories.categories.flatMap(cat => cat.items || []),
                ...data.catalogItemsAndCategories.uncategorizedItems
            ]);
        }
    }, [data]);


    const handleOpenEditItem = (item) => {
        // console.log("Opening edit item popup for:", item);
        setItemToEdit(item);
        setIsEditItemPopupOpen(true);
        setIsItemDetailPopupOpen(false);
    };


    const handleReorderSuccess = useCallback((updatedOrder) => {
        setCategories(updatedOrder.categoriesWithItems);
        setItems([
            ...updatedOrder.categoriesWithItems.flatMap(cat => cat.items || []),
            ...(updatedOrder.itemsWithoutCategory || [])
        ]);
        setSuccessMessageContent({
            title: "Catalog Updated",
            message: "The catalog order has been updated successfully!"
        });
        setIsSuccessMessageOpen(true);

        // Refetch to ensure server and client states are in sync
        refetch();
    }, [refetch]);

    const handleError = useCallback((error) => {
        console.error('An error occurred:', error);
        setSuccessMessageContent({
            title: "Error",
            message: "An error occurred while reordering. The changes may not have been saved. Please try again or reload the page."
        });
        setIsSuccessMessageOpen(true);
    }, []);

    const categoryRefs = useRef({});

    const scrollToCategory = (categoryId) => {
        categoryRefs.current[categoryId]?.scrollIntoView({ behavior: 'smooth' });
    };

    const getCategoryItemCount = (categoryName) => {
        return items.filter(item => item.category === categoryName).length;
    };

    const toggleCategoryExpansion = (categoryId) => {
        setCategories(categories.map(cat =>
            cat.id === categoryId ? { ...cat, isExpanded: !cat.isExpanded } : cat
        ));
    };

    const handleAddItem = async (newItem) => {
        try {
            // Add a loading state if needed
            const result = await optimizedCreateItem({
                ...newItem,
                catalog: catalogId,
            });

            if (result.data) {
                setSuccessMessageContent({
                    title: "Item Added",
                    message: "The item has been added successfully!"
                });
            }
        } catch (error) {
            console.error('Create item error:', error);
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to add item: ${error.message}`
            });
        } finally {
            setIsAddItemPopupOpen(false);
            setIsSuccessMessageOpen(true);
        }
    };


    const handleAddCategory = async (newCategoryName) => {
        try {
            await optimizedCreateCategory({
                name: newCategoryName,
                catalog: catalogId,
                isVisible: true,
            });

            setSuccessMessageContent({
                title: "Category Added",
                message: "The category has been added successfully!"
            });
        } catch (error) {
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to add category: ${error.message}`
            });
        }
        setIsAddCategoryPopupOpen(false);
        setIsSuccessMessageOpen(true);
    };

    const handleDeleteItemClick = (item) => {
        setItemToDelete(item);
        setIsDeleteItemConfirmationOpen(true);
        setIsItemDetailPopupOpen(false); // Close the item detail popup if open
    };

    const handleDeleteItemConfirm = async () => {

        if (!itemToDelete) return;

        try {
            const result = await optimizedDeleteItem(itemToDelete);

            if (result.data.deleteItem === 'Item removed') {
                // Update local state
                setItems(prevItems => prevItems.filter(item => item !== itemToDelete));


                setSuccessMessageContent({
                    title: "Item Deleted",
                    message: "The item has been deleted successfully!"
                });
            } else {
                throw new Error('Failed to delete item');
            }
        } catch (error) {
            console.error('Error deleting item:', error);
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to delete item: ${error.message}`
            });
        } finally {
            setIsDeleteItemConfirmationOpen(false);
            setItemToDelete(null);
            setIsSuccessMessageOpen(true);
            refetch(); // Refetch to ensure server and client states are in sync
        }
    };

    const handleUpdateItem = async (updatedItem) => {
        try {
            const result = await optimizedUpdateItem({
                id: updatedItem.id,
                ...updatedItem
            });
            if (result.data) {
                setSuccessMessageContent({
                    title: "Item Updated",
                    message: "The item has been updated successfully!"
                });
            };
        } catch (error) {
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to update item: ${error.message}`
            });
        }
        refetch();

        setIsEditItemPopupOpen(false);
    };


    const handleSuccessMessageClose = () => {
        setIsSuccessMessageOpen(false);
    };


    const handleEditCategory = (category) => {
        setSelectedCategory(category);
        setIsEditCategoryPopupOpen(true);
    };

    const handleEditCategorySubmit = async (selectedCategory) => {
        try {
            const result = await optimizedUpdateCategory({
                id: selectedCategory.id,
                ...selectedCategory
            });

            if (result.data) {
                setSuccessMessageContent({
                    title: "Category Updated",
                    message: "The category has been updated successfully!"
                });
            };
        } catch (error) {
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to update category: ${error.message}`
            });
        }
        setIsEditCategoryPopupOpen(false);
        setIsSuccessMessageOpen(true);
    };


    // In Catalog.jsx

    const handleToggleItemVisibility = async (itemId, currentVisibility) => {
        if (!itemId) {
            console.error('Invalid itemId provided to handleToggleItemVisibility');
            setSuccessMessageContent({
                title: "Error",
                message: "Could not update item visibility: Invalid item ID"
            });
            setIsSuccessMessageOpen(true);
            return;
        }

        try {
            await optimizedToggleItemVisibility(itemId, currentVisibility);

            const visibilityStatus = !currentVisibility ? 'visible' : 'hidden';
            setSuccessMessageContent({
                title: "Visibility Updated",
                message: `Item is now ${visibilityStatus}`
            });

            // Update local state to match the new visibility
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === itemId
                        ? { ...item, isVisible: !currentVisibility }
                        : item
                )
            );

            // If the item detail popup is open and it's the same item, update it there too
            if (selectedItem?.id === itemId) {
                setSelectedItem(prev => prev ? { ...prev, isVisible: !currentVisibility } : null);
            }

        } catch (error) {
            console.error("Error toggling item visibility:", error);
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to update visibility: ${error.message}`
            });

            // Optionally refetch to ensure UI is in sync with server
            refetch();
        } finally {
            setIsSuccessMessageOpen(true);
        }
    };


    const handleToggleCategoryVisibility = async (categoryId, currentVisibility) => {
        if (!categoryId) {
            console.error('Invalid categoryId provided to handleToggleCategoryVisibility');
            setSuccessMessageContent({
                title: "Error",
                message: "Could not update category visibility: Invalid category ID"
            });
            setIsSuccessMessageOpen(true);
            return;
        }

        try {
            await optimizedToggleCategoryVisibility(categoryId, currentVisibility);

            // Update local state
            setCategories(prevCategories =>
                prevCategories.map(category =>
                    category.id === categoryId
                        ? { ...category, isVisible: !currentVisibility }
                        : category
                )
            );

            const visibilityStatus = !currentVisibility ? 'visible' : 'hidden';
            setSuccessMessageContent({
                title: "Category Updated",
                message: `Category is now ${visibilityStatus}`
            });

            // If the category being toggled is currently selected, update its state
            if (selectedCategory?.id === categoryId) {
                setSelectedCategory(prev =>
                    prev ? { ...prev, isVisible: !currentVisibility } : null
                );
            }

        } catch (error) {
            console.error("Error toggling category visibility:", error);
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to update category visibility: ${error.message}`
            });

            // Refetch to ensure UI is in sync with server
            refetch();
        } finally {
            setIsSuccessMessageOpen(true);
        }
    };

    const handleDeleteCategoryClick = (category) => {
        setSelectedCategory(category);
        setIsDeleteConfirmationOpen(true);
    };

    const handleDeleteCategoryConfirm = async () => {
        if (!selectedCategory) return;

        try {
            await optimizedDeleteCategory(selectedCategory.id);

            setSuccessMessageContent({
                title: "Category Deleted",
                message: "The category and all its items have been deleted successfully."
            });
        } catch (error) {
            console.error("Error deleting category:", error);
            setSuccessMessageContent({
                title: "Error",
                message: `Failed to delete category: ${error.message}`
            });
        } finally {
            setIsDeleteConfirmationOpen(false);
            setSelectedCategory(null);
            setIsSuccessMessageOpen(true);
        }
    };




    const addQuickItem = (categoryId, categoryName) => {
        setQuickAddCategory({ id: categoryId, name: categoryName });
        setIsAddItemPopupOpen(true);
    };

    const filteredItems = items.filter(item =>
        filter === 'all' || (filter === 'shown' && item.isVisible) || (filter === 'hidden' && !item.isVisible)
    );

    const categorizedItems = categories.map(category => ({
        ...category,
        items: filteredItems.filter(item => item.category === category.name)
    }));

    const uncategorizedItems = filteredItems.filter(item => item.category === null);

    const categoriesRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const scroll = (scrollOffset) => {
        if (categoriesRef.current) {
            categoriesRef.current.scrollLeft += scrollOffset;
        }
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - categoriesRef.current.offsetLeft);
        setScrollLeft(categoriesRef.current.scrollLeft);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // const handleToggleItemVisibility = (itemId) => {
    //     setItems(items.map(item =>
    //         item.id === itemId ? { ...item, isVisible: !item.isVisible } : item
    //     ));
    // };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - categoriesRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust scrolling speed
        categoriesRef.current.scrollLeft = scrollLeft - walk;
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isDragging, startX, scrollLeft]);

    const handleTouchStart = (e) => {
        setIsDragging(true);
        setStartX(e.touches[0].pageX - categoriesRef.current.offsetLeft);
        setScrollLeft(categoriesRef.current.scrollLeft);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.touches[0].pageX - categoriesRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        categoriesRef.current.scrollLeft = scrollLeft - walk;
    };


    const handleOpenItemDetail = (item) => {
        setSelectedItem(item);
        setIsItemDetailPopupOpen(true);
    };

    const handleCloseItemDetail = () => {
        setSelectedItem(null);
        setIsItemDetailPopupOpen(false);
    };

    const refetchCatalog = useCallback(async () => {
        try {
            const { data } = await refetch();
            if (data && data.catalogItemsAndCategories) {
                const categoriesWithItemCount = data.catalogItemsAndCategories.categories.map(cat => ({
                    ...cat,
                    isExpanded: true,
                    items: cat.items || [],
                    itemCount: cat.items ? cat.items.length : 0
                }));

                setCategories(categoriesWithItemCount);
                setItems([
                    ...data.catalogItemsAndCategories.categories.flatMap(cat => cat.items || []),
                    ...data.catalogItemsAndCategories.uncategorizedItems
                ]);
            }
        } catch (error) {
            console.error('Error refetching catalog data:', error);
            handleError(error);
        }
    }, [refetch, handleError]);


    if (loading || !user || !catalogId) return <LoadingSpinner />;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <Layout
            title="Catalog"
            description="Manage your catalog's items and categories"
        >

            <div className='min-h-screen mb-12'>
                {/* <div className="p-1 sm:p-1 lg:p-8 bg-gray-100]"> */}
                {/* Top bar */}
                <div className="catalog-actions flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 space-y-4 sm:space-y-0">
                    <div className="relative w-full sm:w-auto max-w-md">
                        <input
                            type="text"
                            placeholder="Search..."
                            className="w-full sm:w-auto pl-10 pr-4 py-2 rounded-xl border border-gray-300 bg-transparent focus:outline-none focus:ring-2 focus:ring-[#191970]"
                        />
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    </div>
                    <div className="flex space-x-2 sm:space-x-4 w-full sm:w-auto text-xs md:text-sm">
                        <button onClick={() => setIsAddItemPopupOpen(true)} className="flex-1 sm:flex-none bg-white text-#191970 px-3 py-2 rounded-lg flex items-center justify-center border-[#191970]">
                            <Plus className="w-4 h-4 mr-2" /> New Item
                        </button>
                        <button onClick={() => setIsAddCategoryPopupOpen(true)} className="flex-1 sm:flex-none bg-[#191970] text-white px-3 py-2 rounded-lg flex items-center justify-center">
                            <Plus className="w-4 h-4 mr-2" /> New Category
                        </button>
                        {/* <button
                            onClick={() => setIsManagementMode(!isManagementMode)}
                            className={`px-3 py-2 rounded-lg flex items-center justify-center ${isManagementMode ? 'bg-gray-200 text-gray-800' : 'bg-blue-500 text-white'
                                }`}
                        >
                            {isManagementMode ? 'Exit Management' : 'Manage Catalog'}
                        </button> */}
                    </div>
                </div>

                <ErrorBoundary fallback={<div>Something went wrong. Please try again later.</div>}>
                    {isManagementMode ? (
                        <CatalogManagement
                            categories={categories}
                            items={items}
                            catalogId={catalogId}
                            onReorderSuccess={handleReorderSuccess}
                            onError={handleError}
                        />
                    ) : (
                        <>

                            {/* Categories overview */}
                            {categories.length > 0 && ( // Only appear if there is at least 1 category
                                <div className="bg-white rounded-2xl shadow-md p-4 sm:p-6 mb-6 max-w-full overflow-hidden">
                                    <h2 className="text-xl font-bold mb-4">Categories</h2>
                                    <div className="relative">
                                        {/* <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
                                        <button onClick={() => scroll(-100)} className="p-1 rounded-full bg-white shadow-md hover:bg-gray-100">
                                            <ChevronLeft size={10} />
                                        </button>
                                    </div>
                                    <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
                                        <button onClick={() => scroll(100)} className="p-1 rounded-full bg-white shadow-md hover:bg-gray-100">
                                            <ChevronRight size={10} />
                                        </button>
                                    </div> */}
                                        <div
                                            ref={categoriesRef}
                                            className="flex overflow-x-auto scrollbar-hide space-x-2 sm:space-x-4 pb-2 px-8 cursor-grab active:cursor-grabbing"
                                            onMouseDown={handleMouseDown}
                                            onTouchStart={handleTouchStart}
                                            onTouchMove={handleTouchMove}
                                            onTouchEnd={handleMouseUp}
                                            style={{
                                                scrollbarWidth: 'none',
                                                msOverflowStyle: 'none',
                                                userSelect: 'none',
                                            }}
                                        >
                                            {categories.map((category) => (
                                                <button
                                                    key={category.id}
                                                    onClick={() => scrollToCategory(category.id)}
                                                    className="flex-shrink-0 bg-white rounded-md px-3 py-3 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium hover:bg-gray-200 transition-colors border-gray-300"
                                                >
                                                    {category.name} <span className='bg-blue-300 text-#191970 p-1 px-2 ml-2 rounded-sm font-bold aspect-square'>{category.itemCount || 0}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Items section */}
                            <div className='items-section mb-16'>
                                <div className="flex justify-between items-center mb-4">
                                    <h4 className="text-lg font-bold">Items</h4>
                                    {/* <button
                                        className="flex items-center bg-white text-[#191970]"
                                        onClick={() => setFilter(filter === 'all' ? 'shown' : filter === 'shown' ? 'hidden' : 'all')}
                                    >
                                        <Filter className="w-4 h-4 mr-2" />
                                        <span className="hidden sm:inline">{filter === 'all' ? 'All' : filter === 'shown' ? 'Shown' : 'Hidden'}</span>
                                    </button> */}
                                </div>

                                {/* Categorized items */}
                                <div className="space-y-8">
                                    {categories.map((category) => (
                                        <div key={category.id} ref={el => categoryRefs.current[category.id] = el}>
                                            <div className="flex justify-between items-center bg-gray-200 p-2 rounded-t-xl">
                                                <h6 className="text-base lg:pl-5 font-semibold">{category.name}</h6>
                                                <div className="flex items-center ">
                                                    {/* <Toggle
                                                        isOn={category.isVisible}
                                                        onToggle={() => handleToggleCategoryVisibility(category.id)}
                                                    /> */}
                                                    <button
                                                        onClick={() => addQuickItem(category.id, category.name)}
                                                        className="bg-[#191970] text-white px-2 py-1 sm:px-3 sm:py-1 rounded-full text-xs sm:text-sm flex items-center"
                                                    >
                                                        <Plus className="w-3 h-3" /> <span className="hidden sm:inline">Quick Add</span>
                                                    </button>
                                                    <button
                                                        onClick={() => handleEditCategory(category)}
                                                        className="text-blue-500 hover:text-blue-700 bg-transparent px-3 ml-7"
                                                    >
                                                        <Edit size={17} />
                                                    </button>
                                                    <button
                                                        onClick={() => handleDeleteCategoryClick(category)}
                                                        className="text-red-500 hover:text-red-900 bg-transparent px-3 ml-1"
                                                    >
                                                        <Trash size={17} />
                                                    </button>
                                                    <button onClick={() => toggleCategoryExpansion(category.id)} className='bg-white'>
                                                        {category.isExpanded ? <ChevronUp /> : <ChevronDown />}
                                                    </button>
                                                </div>
                                            </div>


                                            {category.isExpanded && (
                                                <div>
                                                    {category.items.length > 0 ? (
                                                        <ItemGrid
                                                            items={category.items.filter(item =>
                                                                filter === 'all' ||
                                                                (filter === 'shown' && item.isVisible) ||
                                                                (filter === 'hidden' && !item.isVisible)
                                                            )}
                                                            onToggleItemVisibility={handleToggleItemVisibility}
                                                            onOpenDetail={handleOpenItemDetail}
                                                        />
                                                    ) : (
                                                        <p className="text-gray-500 text-sm text-center pt-2">Let's fill this category! Use the quick add button (+) to add items. </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>


                                {/* Uncategorized items */}
                                {data.catalogItemsAndCategories.uncategorizedItems.length > 0 && (
                                    <div className="mt-8">
                                        {categories.length > 0 && (  // only display heading if there are other categories
                                            <h6 className="text-base font-semibold bg-gray-200 h-14 rounded-t-xl p-2 pt-4">Other Items</h6>
                                        )}
                                        <ItemGrid
                                            items={data.catalogItemsAndCategories.uncategorizedItems.filter(item =>
                                                filter === 'all' ||
                                                (filter === 'shown' && item.isVisible) ||
                                                (filter === 'hidden' && !item.isVisible)
                                            )}
                                            onToggleItemVisibility={handleToggleItemVisibility}
                                            onOpenDetail={handleOpenItemDetail}
                                        />

                                    </div>
                                )}

                                {data.catalogItemsAndCategories.uncategorizedItems.length == 0 && categories.length == 0 && (
                                    <div>
                                        <p className='text-gray-500 text-center'>Let's get started! Click the 'New Item' or 'New Category' buttons above to add your first products and start building your catalog.</p>
                                    </div>
                                )
                                }

                            </div>
                        </>
                    )}

                </ErrorBoundary>

                {/* Popups and modals */}
                <AddItemPopup
                    isOpen={isAddItemPopupOpen}
                    onClose={() => {
                        setIsAddItemPopupOpen(false);
                        setQuickAddCategory(null);
                    }}
                    onSubmit={handleAddItem}
                    categories={categories}
                    quickAddCategory={quickAddCategory}
                    catalogId={catalogId}
                />

                {isEditItemPopupOpen && itemToEdit && (
                    <EditItemPopup
                        isOpen={isEditItemPopupOpen}
                        onClose={() => {
                            // console.log("Closing edit item popup");
                            setIsEditItemPopupOpen(false);
                            setItemToEdit(null);
                        }}
                        onSubmit={handleUpdateItem}
                        item={itemToEdit}
                        categories={categories}
                        catalogId={catalogId}
                    />
                )}

                <AddCategoryPopup
                    isOpen={isAddCategoryPopupOpen}
                    onClose={() => setIsAddCategoryPopupOpen(false)}
                    onSubmit={handleAddCategory}
                    catalogId={catalogId}
                />

                <ItemDetailPopup
                    item={selectedItem}
                    isOpen={isItemDetailPopupOpen}
                    onClose={() => setIsItemDetailPopupOpen(false)}
                    onEdit={handleOpenEditItem}
                    onDelete={handleDeleteItemClick}
                    onToggleVisibility={handleToggleItemVisibility}
                />

                <EditCategoryPopup
                    isOpen={isEditCategoryPopupOpen}
                    onClose={() => setIsEditCategoryPopupOpen(false)}
                    onSubmit={handleEditCategorySubmit}
                    category={selectedCategory}
                />

                <DeleteConfirmationPopup
                    isOpen={isDeleteConfirmationOpen}
                    onClose={() => setIsDeleteConfirmationOpen(false)}
                    onConfirm={handleDeleteCategoryConfirm}
                    itemName="category"
                />

                <DeleteConfirmationPopup
                    isOpen={isDeleteItemConfirmationOpen}
                    onClose={() => {
                        setIsDeleteItemConfirmationOpen(false);
                        setItemToDelete(null);
                    }}
                    onConfirm={handleDeleteItemConfirm}
                    itemName="item"
                />

                {/* <SuccessMessagePopup
                    isOpen={isSuccessMessageOpen}
                    onClose={() => setIsSuccessMessageOpen(false)}
                    title={successMessageContent.title}
                    message={successMessageContent.message}
                /> */}

                <SuccessMessagePopup
                    isOpen={isSuccessMessageOpen}
                    onClose={() => {
                        setIsSuccessMessageOpen(false);
                        // Only refetch for partial updates, not for new item creation
                        if (successMessageContent.title === "Partial Update") {
                            refetchCatalog();
                        }
                    }}
                    title={successMessageContent.title}
                    message={successMessageContent.message}
                />


                {/* <ReturnToOnboardingButton /> */}
            </div>
        </Layout >
    );
};

export default Catalog;
import React from "react";


const DeleteCatalogModal = React.memo(({
    isOpen,
    catalog,
    isConfirming,
    deletionResult,
    onConfirm,
    onClose
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
                {!deletionResult ? (
                    <>
                        <h4 className="text-lg font-semibold mb-4">Delete Catalog</h4>
                        <p className="mb-4">
                            Are you sure you want to delete the catalog "{catalog?.name}"?
                            This action cannot be undone.
                        </p>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={onClose}
                                className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={onConfirm}
                                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors disabled:opacity-50"
                                disabled={isConfirming}
                            >
                                {isConfirming ? 'Deleting...' : 'Delete Catalog'}
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <h4 className="text-lg font-semibold mb-4">Deletion Result</h4>
                        <div className={`mb-4 ${deletionResult.success ? 'text-green-600' : 'text-red-600'}`}>
                            <p>{deletionResult.message}</p>
                            {deletionResult.success && (
                                <p className="mt-2">
                                    Deleted:
                                    <ul className="list-disc ml-5">
                                        <li>{deletionResult.deletedCategories} categories</li>
                                        <li>{deletionResult.deletedItems} items</li>
                                        <li>{deletionResult.deletedImages} images</li>
                                    </ul>
                                </p>
                            )}
                        </div>
                        <div className="flex justify-end">
                            <button
                                onClick={onClose}
                                className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
                            >
                                Close
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});

const DeleteAccountModal = React.memo(({
    isOpen,
    confirmation,
    setConfirmation,
    onConfirm,
    onClose,
    deletionResult
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
                {!deletionResult ? (
                    <>
                        <h4 className="text-lg font-semibold mb-4">Delete Account</h4>
                        <p className="mb-4">
                            Are you absolutely sure you want to delete your account? This action cannot be undone
                            and will result in the permanent loss of all your data, including:
                        </p>
                        <ul className="list-disc ml-5 mb-4 text-gray-600">
                            <li>All your catalogs</li>
                            <li>All categories and items</li>
                            <li>All uploaded images</li>
                            <li>Account settings and preferences</li>
                        </ul>
                        <div className="mb-4">
                            <p className="mb-2">To confirm, please type "delete my account":</p>
                            <input
                                type="text"
                                value={confirmation}
                                onChange={(e) => setConfirmation(e.target.value)}
                                className="w-full p-2 border bg-white text-black rounded transition-colors
                                         focus:border-red-500 focus:ring-1 focus:ring-red-500"
                                placeholder="Type 'delete my account' to confirm"
                            />
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={onClose}
                                className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={onConfirm}
                                disabled={confirmation.toLowerCase() !== 'delete my account'}
                                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 
                                         transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Delete Account
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <h4 className="text-lg font-semibold mb-4">Account Deletion Result</h4>
                        <div className={`mb-4 ${deletionResult.success ? 'text-green-600' : 'text-red-600'}`}>
                            <p>{deletionResult.message}</p>
                            {deletionResult.success && (
                                <p className="mt-2">
                                    Deleted:
                                    <ul className="list-disc ml-5">
                                        <li>{deletionResult.deletedCatalogs} catalogs</li>
                                        <li>{deletionResult.deletedCategories} categories</li>
                                        <li>{deletionResult.deletedItems} items</li>
                                        <li>{deletionResult.deletedImages} images</li>
                                    </ul>
                                </p>
                            )}
                        </div>
                        <div className="flex justify-end">
                            <button
                                onClick={onClose}
                                className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
                            >
                                Close
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});

const ChangePasswordModal = React.memo(({ isOpen, onClose }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (newPassword !== confirmPassword) {
            setError('New passwords do not match');
            return;
        }

        if (newPassword.length < 8) {
            setError('New password must be at least 8 characters long');
            return;
        }

        setIsLoading(true);
        try {
            const { data } = await changePassword({
                variables: {
                    currentPassword,
                    newPassword
                }
            });

            if (data.changePassword.success) {
                toast.success('Password changed successfully');
                onClose();
            } else {
                setError(data.changePassword.message);
            }
        } catch (error) {
            setError('Failed to change password. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
                <h4 className="text-lg font-semibold mb-4">Change Password</h4>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium mb-1">Current Password</label>
                        <input
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            className="w-full p-2 border rounded focus:border-blue-500 focus:ring-1"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-1">New Password</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="w-full p-2 border rounded focus:border-blue-500 focus:ring-1"
                            required
                            minLength={8}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-1">Confirm New Password</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="w-full p-2 border rounded focus:border-blue-500 focus:ring-1"
                            required
                        />
                    </div>
                    {error && (
                        <p className="text-red-500 text-sm">{error}</p>
                    )}
                    <div className="flex justify-end space-x-2 pt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="px-4 py-2 bg-[#191970] text-white rounded-md hover:bg-opacity-90 
                                     transition-colors disabled:opacity-50"
                        >
                            {isLoading ? 'Changing...' : 'Change Password'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
});

export { DeleteCatalogModal, DeleteAccountModal, ChangePasswordModal };